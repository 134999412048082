import { SecondTitle, ContentTypesBuildingVentures } from "./styled";
import TypeCard from "./typeCard";

const TypesBuildingVentures = () => {
  return (
    <ContentTypesBuildingVentures className="__wrap">
      <SecondTitle>Fala para gente qual é o seu sonho?</SecondTitle>
      <TypeCard />
    </ContentTypesBuildingVentures>
  );
};

export default TypesBuildingVentures;
