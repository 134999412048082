import styled from "styled-components";

const Title = styled.h1`
  color: white;
  font-weight: 500;
  font-size: 36px;
  line-height: 40px;
  width: 40%;
  margin-bottom: 20px;

  @media only screen and (max-width: 430px) {
    width: 100%;
    text-align: center;
    font-size: 170%;
  }

  span {
    color: #d25148;
  }
`;

export { Title };
