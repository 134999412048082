import axios from "axios";
import { useEffect, useState } from "react";
import funcionario from "../../Assets/Images/funcionario1.png";
import { Item, Items, TeamStyle, Title } from "./styled";

const Team = () => {
  const [people, setPeople] = useState([]);

  useEffect(() => {
    axios
      .get("https://freedomplanejamento.com.br/painel/wp-json/acf/v3/posts", {
        // headers: {
        //   "X-WP-Nonce": "wp_rest",
        // },
      })
      .then((response) => {
        const teamPeople = response.data.filter((person) => {
          return person["acf"]["socio"][0] !== "Sim";
        });
        setPeople(teamPeople);
      });
  }, []);

  return (
    <TeamStyle>
      <div className="__wrap">
        <Title>
          Conheça o time responsável pela
          <br />
          <span>realização dos seus sonhos!</span>
        </Title>
        <Items>
          {people.map((person) => (
            <Item key={person.id} bg={person["acf"]["foto_do_funcionario"]}>
              <div
                className="foto"
                style={{
                  backgroundImage: `url(${person["acf"]["foto_do_funcionario"]})`,
                }}
              ></div>
              <p className="name">{person["acf"]["nome"]}</p>
              <p className="cargo">{person["acf"]["cargo"]}</p>
              <p className="description">{person["acf"]["descricao"]}</p>
            </Item>
          ))}
        </Items>
      </div>
    </TeamStyle>
  );
};

export default Team;
