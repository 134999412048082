import { Title, BackgroundStyle, Flex } from "./styled";
import { Link } from "react-router-dom";
import { ButtonTransparent } from "../../Components/Buttons";

const WhatsYourDream = () => {
  return (
    <BackgroundStyle>
      <div className="__wrap">
        <Flex>
          <Title>Qual é o seu sonho?</Title>
          <Flex direction={"row"}>
            <ButtonTransparent widths={"190px"}>
              <Link to="/empreendimentos?tipo=apartamento">Apartamento</Link>
            </ButtonTransparent>
            <ButtonTransparent widths={"190px"}>
              {" "}
              <Link to="/empreendimentos?tipo=casa">Casa</Link>
            </ButtonTransparent>
            <ButtonTransparent widths={"190px"}>
              {" "}
              <Link to="/empreendimentos?tipo=terreno">Terreno</Link>
            </ButtonTransparent>
          </Flex>
        </Flex>
      </div>
    </BackgroundStyle>
  );
};

export default WhatsYourDream;
