import styled from "styled-components";

const HeaderStyle = styled.header`
  padding: 24px 0;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  transition: padding 0.4s ease, background-color 0.4s ease,
    box-shadow 0.4s ease;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2);

  a img {
    width: 160px;
    height: 45px;
    transition: width 0.4s ease, height 0.4s ease;
  }

  &._actived {
    background-color: white;
    padding: 14px 0;
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.2);

    div {
      color: #575656;

      a img {
        width: 120px;
        height: 30px;
        transition: width 0.4s ease, height 0.4s ease;
      }
    }
  }

  @media only screen and (max-width: 430px) {
    padding: 10px 20px;
    background-color: white;

    &._actived {
      padding: 10px 20px !important;
    }

    a img {
      width: 120px;
      height: 35px;
      position: relative;
      z-index: 12;
    }

    .MenuButton {
      position: relative;
      z-index: 12;
      cursor: pointer;
    }
  }
`;

const NavLink = styled.div`
  font-size: 16px
  font-weight: 500;
  margin-left: 48px;
  transition: color 0.4s ease;
  position: relative;
  display: block;
  color: white;

  @media only screen and (max-width: 430px) {
    color: #575656;
    margin-left: 0px;
    margin-bottom: 48px;
    font-weight: bold;

    &:first-child {
      margin-top: 180px;
    }
  }
  
  &:after { 
    content: '';
    width: 0%;
    height: 2px;
    border-radius: 20px;
    background-color: #D25148;
    position: absolute;
    bottom: -2px;
    left: 0;
    display: block;
    transition: width 0.4s ease;
  }

  &:hover { 
    color: #D25148;

    &:after { 
      width: 100%;
    }
  }
`;

const NavStyle = styled.nav`
  justify-content: space-between;
  display: flex;
  align-items: center;

  @media only screen and (max-width: 430px) {
    position: fixed;
    flex-direction: column;
    justify-content: flex-start;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: white;
    transition: transform 0.4s ease;
    z-index: 10;
    transform: translateX(-100%);

    &.__opened {
      transform: translateX(0%);
    }

    a {
      margin-left: 0 !important;
    }
  }
`;

export { HeaderStyle, NavLink, NavStyle };
