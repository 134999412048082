import styled from "styled-components";
import banner from "../../Assets/Images/bgempred.png";

const Title = styled.h1`
  color: white;
  font-weight: 500;
  font-size: 56px;
  line-height: 40px;
  width: 100%;
  text-align: center;
  margin-bottom: 10px;

  @media only screen and (max-width: 430px) {
    font-size: 35px;
    margin-top: 20px;
    line-height: 20px;
  }

  h2 {
    font-size: 28px;
    width: 50%;
    font-weight: normal;
    margin-top: 32px;
  }

  span {
    color: #d25148;
  }
`;

const Banner = styled.div`
  ${(props) => {
    return props.backgroundNow
      ? `background-image: url(${props.backgroundNow});`
      : `background-image: url(${banner});`;
  }};

  height: 80vh;

  background-size: cover;
  background-position: center;

  .__wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  @media only screen and (max-width: 430px) {
    padding: 20px;
  }
`;

// const Seletor = styled.div`
//   background-color: white;
//   padding: 5px;
//   margin-top: 50px;
//   border-radius: 3px;
//   width: 634px;
//   height: 80px;

//   @media only screen and (max-width: 430px) {
//     width: 100%;
//     height: auto;

//     > div {
//       flex-direction: column;
//     }
//   }

//   .item {
//     cursor: pointer;
//     width: 33%;
//     transition: all 0.4s ease;
//     box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.1);

//     @media only screen and (max-width: 430px) {
//       width: 100%;
//       margin: 5px 0;
//       border-radius: 5px;
//       padding: 20px 10px;
//       box-shadow: 0 0 5px 0px rgba(0, 0, 0, 0.2);
//     }

//     &:hover,
//     &.__actived {
//       background-color: #d25148;
//       color: white;
//       border-radius: 3px;
//       svg {
//         line,
//         path {
//           stroke: white;
//         }
//       }
//     }
//     p {
//       margin-right: 5px;
//     }
//   }
// `;

const Flex = styled.div`
  display: flex;
  height: 100%;

  ${(props) => {
    let propsStyles = props.direction
      ? `flex-direction: ${props.direction};`
      : "flex-direction: row;";

    propsStyles += props.alignItems
      ? `align-items: ${props.alignItems};`
      : "align-items: center;";

    propsStyles += props.justifyContent
      ? `justify-content: ${props.justifyContent};`
      : "justify-content: center;";

    return propsStyles;
  }}

  @media only screen and (max-width: 430px) {
    &.mobile_filter {
      flex-direction: column;
      width: 100%;
    }
  }
`;

const Filters = styled.div`
  background-color: white;
  border-bottom: 2px solid #e7e7e7;
  padding: 50px 0;
  border-bottom: 2px solid #e7e7e7;
  margin-bottom: 50px;

  .desktop_filter {
    @media only screen and (max-width: 430px) {
      gap: 5px;
    }
  }

  .item {
    border: 2px solid #e7e7e7;
    padding: 10px;
    border-radius: 3px;
    width: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    gap: 10px;
    position: relative;
    cursor: pointer;
    transition: 0.4s all ease;

    @media only screen and (max-width: 430px) {
      width: 100%;
      font-size: 12px;
    }

    &.__actived {
      color: white;
      background-color: #d25148;
      border: 2px solid #d25148;

      svg {
        path,
        line {
          stroke: white !important;
        }
      }

      &:hover {
        background-color: white;
        color: #d25148;
      }
    }

    &.__actived-select {
      color: #d25148;

      svg {
        path,
        line {
          fill: #d25148 !important;
          stroke: #d25148 !important;
        }
      }
    }

    &:hover {
      color: #d25148;

      svg {
        path,
        line {
          stroke: #d25148 !important;
        }
      }
    }

    select {
      width: 100%;
      z-index: 1;
      padding-left: 15px;
      background-color: transparent;
      cursor: pointer;
    }
  }

  label {
    border: 2px solid #e7e7e7;
    padding: 10px;
    border-radius: 3px;
    width: auto;
    display: block;
    font-size: 14px;
    position: relative;
    cursor: pointer;
    transition: 0.4s all ease;

    @media only screen and (max-width: 430px) {
      width: 100%;
    }

    &.__actived {
      color: white;
      background-color: #d25148;
      border: 2px solid #d25148;

      &:hover {
        color: white;
      }
    }

    &.__actived-select {
      color: #d25148;

      svg {
        path {
          fill: #d25148 !important;
          stroke: #d25148 !important;
        }
      }
    }

    &:hover {
      color: #d25148;

      svg {
        path {
          fill: #d25148 !important;
          stroke: #d25148 !important;
        }
      }
    }

    select {
      width: 100%;
      z-index: 1;
      padding-left: 15px;
      background-color: transparent;
      cursor: pointer;
    }

    svg {
      z-index: 0;
      position: absolute;
      top: 50%;
      left: 10px;
      transform: translateY(-50%);
      cursor: pointer;
      path {
        transition: 0.4s fill ease, 0.4s stroke ease;
        fill: #575656;
        stroke: #575656;
      }
    }
  }

  @media only screen and (max-width: 430px) {
    padding: 20px;
    form > div {
      flex-direction: column;
    }
  }
`;

const Separetor = styled.span`
  width: 2px;
  height: 48px;
  background-color: #e7e7e7;
  margin: 0 20px;
  display: block;

  @media only screen and (max-width: 430px) {
    width: 100%;
    height: 2px;
    margin: 20px 0;
  }
`;

const Space = styled.span`
  width: 10px;
  height: 0px;
  display: block;

  @media only screen and (max-width: 430px) {
    height: 20px;
    width: 0;
  }
`;

const Buildings = styled.div`
  .__wrap {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  .BuildCard {
    margin-bottom: 50px;

    @media only screen and (max-width: 430px) {
      width: 100%;
      margin-bottom: 20px;
    }

    &:nth-child(3n) {
      // background-color: red;
      margin-left: auto;

      @media only screen and (max-width: 430px) {
        margin-left: 0;
        margin-right: 0;
      }
    }

    &:nth-child(3n - 1) {
      // background-color: blue;
      margin-left: auto;

      @media only screen and (max-width: 430px) {
        margin-left: 0;
        margin-right: 0;
      }

      &:last-child {
        margin-left: 25px;
        @media only screen and (max-width: 430px) {
          margin-left: 0;
        }
      }
    }
  }

  @media only screen and (max-width: 430px) {
    padding: 0 20px;
  }
`;

// const ImageBuild = styled.div`
//   background: red;
//   display: flex;
//   align-items: center;
// `;

const ImageBuild = styled.div`
  height: 305px;
  width: 385px;
  position: relative;
  background-size: 175%;
  background-repeat: no-repeat;
  background-position: center center;
  font-weight: 500;
  color: white;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-end;
  border-radius: 16px;
  padding: 20px;
  transition: background-size 0.5s ease;
  cursor: pointer;
  pointer-events: auto;

  @media only screen and (max-width: 430px) {
    width: 100%;
    height: 250px;
  }

  &:hover {
    background-size: 200%;

    p {
      transform: translateY(0%) !important;
    }
    .seeMore {
      transform: translateY(0%);
      opacity: 1;
    }
  }

  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    border-radius: 16px;
    z-index: 0;
    height: 100%;
    background: linear-gradient(to top, #181818e8 0%, transparent 200%);
  }

  p {
    z-index: 1;
    position: relative;
    font-size: 20px;
    // transform: translateY(130%);
    transition: transform 0.5s ease;
  }

  span {
    position: absolute;
    font-weight: bold;
    background-color: #d25148;
    color: white;
    z-index: 1;
    position: absolute;
    top: 20px;
    left: -10px;
    font-size: 14px;
    padding: 5px;
    border-radius: 4px;
  }
`;

const SeeMoreCard = styled.strong`
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  font-weight: 400;
  margin-top: 10px;
  transform: translateY(200%);
  color: white;
  transition: transform 0.4s ease, opacity 0.4s ease;
  z-index: 1;
  opacity: 0;
  pointer-events: none;

  &:after {
    content: "";
    width: 6px;
    height: 6px;
    border-top: 2px solid;
    border-right: 2px solid;
    transform: rotate(45deg) translate(5px, -5px);
  }
`;

const Datas = styled.div`
  padding: 10px 0;
  color: #575656;

  @media only screen and (max-width: 430px) {
    font-size: 14px;
  }
  svg {
    stroke: #575656;

    path {
      stroke: #575656;
    }
  }
`;

const Circle = styled.div`
  width: 3px;
  height: 3px;
  margin: 0 10px;
  border-radius: 100%;
  background-color: #c4c4c4;
`;

export {
  Banner,
  Buildings,
  Circle,
  Datas,
  Filters,
  Flex,
  ImageBuild,
  SeeMoreCard,
  Separetor,
  Space,
  Title,
};
