import styled from "styled-components";

const PartnersStyle = styled.div`
  background-color: #d3d8db;
`;

const Item = styled.div`
  position: absolute;
  opacity: 0;

  @media only screen and (max-width: 430px) {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    overflow: hidden;
    height: 100%;

    img {
      transform: translateX(20%);
    }
  }

  &._actived {
    opacity: 1;
    z-index: 2;
  }
`;

const ItemsSlider = styled.div`
  position: relative;
  height: 416px;

  @media only screen and (max-width: 430px) {
    height: 40vh;
  }
`;

const Text = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  position: absolute;
  top: 50%;
  right: 15%;
  transform: translateY(-50%);
  width: 30%;

  @media only screen and (max-width: 430px) {
    padding: 0 20px;
    top: 40%;
    width: 100%;
    right: 0%;
  }
`;
const Name = styled.p`
  font-size: 32px;
  font-weight: 500;
  color: #575656;
  margin-bottom: 1px;

  @media only screen and (max-width: 430px) {
    font-size: 22px;
  }
`;

const Cargo = styled.p`
  color: #d25148;
  font-weight: 500;
  margin-bottom: 2px;
  font-size: 22px;

  @media only screen and (max-width: 430px) {
    font-size: 18px;
  }
`;

const Description = styled.p`
  font-size: 16px;
  color: #575656;

  @media only screen and (max-width: 430px) {
    font-size: 14px;
  }
`;

const Bullets = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 15%;
  position: absolute;
  z-index: 3;
  top: 25%;
  right 30%;

  @media only screen and (max-width: 430px) {
    width: 80%;
    top: 10%;
    left: 50%;
    transform: translateX(-50%);
  }
`;

const Bullet = styled.div`
  width: 24px;
  height: 8px;
  border-radius: 10px;
  cursor: pointer;
  background-color: white;
  transition: 0.4s background-color ease;

  &:hover {
    background-color: #d25148;
  }

  &._actived {
    background-color: #d25148;
  }
`;

export {
  Bullet,
  Bullets,
  Cargo,
  Description,
  Item,
  ItemsSlider,
  Name,
  PartnersStyle,
  Text,
};
