import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import back1 from "../../Assets/Images/back1.png";
import back2 from "../../Assets/Images/back2.png";
import back3 from "../../Assets/Images/back3.png";
import Button from "../../Components/Buttons";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import Highlights from "../../Components/Highlights";
import Insta from "../../Components/Insta";
import LastReleases from "../../Components/LastReleases";
import MeetFreedom from "../../Components/MeetFreedom";
import Slidershow from "../../Components/Slidershow";
import TypesBuildingVentures from "../../Components/TypesBuildingVentures";
import WhatsYourDream from "../../Components/WhatsYourDream";
import { Title } from "./styled";

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header />
      <Slidershow backgrounds={[back1, back2, back3]}>
        <div
          className="__wrap"
          style={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "center",
            flexDirection: "column",
            zIndex: 3,
          }}
        >
          <Title>
            Os principais imóveis <br />
            de <span>Salto, Itu e Indaiatuba</span> <br /> você encontra aqui!
          </Title>
          <Button>
            <Link to="/empreendimentos">
              <p>Realize seu sonho</p>
            </Link>
          </Button>
        </div>
      </Slidershow>
      <TypesBuildingVentures />
      <LastReleases />
      <Highlights />
      <MeetFreedom />
      <Insta />
      <WhatsYourDream />
      <Footer />
    </>
  );
};

export default Home;
