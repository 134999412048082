import React from "react";
import { Link } from "react-router-dom";
import ap from "../../Assets/Images/apartamento.png";
import casa from "../../Assets/Images/casa.png";
import lote from "../../Assets/Images/loteamento.png";
import { ContentTypeCard, Card, TextsCard, SeeMoreCard } from "./styled";

const TypeCard = () => {
  return (
    <ContentTypeCard>
      <Card style={{ backgroundImage: `url(${ap})` }}>
        <Link to="/empreendimentos?tipo=apartamento">
          <TextsCard>
            <p>Apartamento</p>
            <SeeMoreCard>Veja Mais</SeeMoreCard>
          </TextsCard>
        </Link>
      </Card>
      <Card style={{ backgroundImage: `url(${casa})` }}>
        <Link to="/empreendimentos?tipo=casa">
          <TextsCard>
            <p>Casa</p>
            <SeeMoreCard>Veja Mais</SeeMoreCard>
          </TextsCard>
        </Link>
      </Card>
      <Card style={{ backgroundImage: `url(${lote})` }}>
        <Link to="/empreendimentos?tipo=terreno">
          <TextsCard>
            <p>Terreno</p>
            <SeeMoreCard>Veja Mais</SeeMoreCard>
          </TextsCard>
        </Link>
      </Card>
    </ContentTypeCard>
  );
};

export default TypeCard;
