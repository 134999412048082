import styled from "styled-components";

const FooterStyle = styled.footer`
  background: #181818;
  padding: 20px;

  .__wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media only screen and (max-width: 430px) {
      flex-direction: column;

      p {
        margin: 20px 0;
        text-align: center;
      }
    }

    p {
      color: #575656;
    }
  }
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  ${(props) => {
    let propsStyles = props.direction
      ? `flex-direction: ${props.direction};`
      : "flex-direction: column;";
    return propsStyles;
  }}

  a {
    &:nth-child(1) {
      margin-right: 20px;
    }

    &:nth-child(3) {
      margin: 0 20px;
    }

    img {
      transition: transform 0.5s ease;
      transform: scale(1);
    }

    &:hover {
      .zap {
        text-decoration: underline;
      }

      img {
        transform: scale(1.1);
      }
    }
  }
`;

export { Flex, FooterStyle };
