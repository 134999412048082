import Button from "../../Components/Buttons";
import { Title, BackgroundStyle } from "./styled";
import { Link } from "react-router-dom";

const MeetFreedom = () => {
  return (
    <BackgroundStyle>
      <div className="__wrap">
        <Title>
          Conheça a Freedom e os <br />
          profissionais que tornam <br />
          <span>seus sonhos possíveis!</span>
        </Title>
        <Button>
          <Link to="/sobre">
            <p>Conheça mais</p>
          </Link>
        </Button>
      </div>
    </BackgroundStyle>
  );
};

export default MeetFreedom;
