import { Title, TalkAbout } from "./styled";
import Button from "../Buttons";
import { Link } from "react-router-dom";

const TalkAboutYourDream = () => {
  return (
    <TalkAbout>
      <div className="__wrap">
        <Title>
          Queremos conversar
          <br /> sobre <span>o seu sonho!</span>
        </Title>
        <p>
          Conte com o apoio de nossa equipe especializada
          <br /> para fazer uma análise detalhada do seu caso e<br /> uma
          avaliação gratuíta de crédito.
        </p>
        <Button>
          <Link to="/contato">Entre em contato</Link>
        </Button>
      </div>
    </TalkAbout>
  );
};

export default TalkAboutYourDream;
