import styled from "styled-components";
import banner from "../../Assets/Images/bg-about.png";

const Title = styled.h1`
  color: white;
  font-weight: 500;
  font-size: 56px;
  line-height: 40px;
  width: 100%;
  text-align: center;
  margin-bottom: 20px;

  h2 {
    font-size: 28px;
    width: 50%;
    font-weight: normal;
    margin-top: 32px;
  }

  span {
    color: #d25148;
  }

  @media only screen and (max-width: 430px) {
    font-size: 40px;
    padding: 0 20px;

    h2 {
      font-size: 18px;
      line-height: 24px;
      margin-top: 10px;
      width: 100%;
    }
  }
`;

const TitleT = styled.h4`
  color: #575656;
  font-weight: 500;
  font-size: 32px;
  line-height: 40px;
  width: 100%;
  margin-bottom: 20px;

  @media only screen and (max-width: 430px) {
    font-size: 28px;
    br {
      display: none;
    }
    h2 {
      width: 100%;
    }
  }

  h2 {
    font-size: 28px;
    width: 50%;
    font-weight: normal;
    margin-top: 32px;
  }

  span {
    color: #d25148;
  }
`;

const Banner = styled.div`
  height: 80vh;
  background-image: url(${banner});
  background-size: cover;
  background-position: center;

  @media only screen and (max-width: 430px) {
    height: 100vh;
  }
`;

const OurStructure = styled.div`
  background-color: rgb(211, 216, 219);
`;

const Message = styled.p`
  background: #181818;
  padding: 60px;
  width: 100%;
  margin-bottom: 30px;
  border-radius: 10px;
  color: white;
  font-size: 26px;
  display: flex;
  font-weight: 500;
  justify-content: flex-start;
  align-items: flex-start;

  img {
    margin-right: 20px;
  }

  @media only screen and (max-width: 430px) {
    font-size: 15px;
    padding: 20px;

    br {
      display: none;
    }

    > img {
      width: 30px !important;
      margin-right: 10px !important;
    }
  }
`;

const Flex = styled.div`
  display: flex;
  height: 100%;

  .txts {
    margin-right: 50px;
    div .img1 {
      margin-right: 20px;
    }
  }

  @media only screen and (max-width: 430px) {
    .txts {
      padding: 0 20px;
      margin-right: 0px;

      img {
        margin: 0px 0px 20px 0 !important;
        width: 100%;
        height: auto;
      }
    }

    .parede {
      width: 100%;
      padding: 0 20px;
      height: auto;
    }
  }

  .parede {
    height: 617px;
  }

  ${(props) => {
    let propsStyles = props.direction
      ? `flex-direction: ${props.direction};`
      : "flex-direction: column;";

    propsStyles += props.alignItems
      ? `align-items: ${props.alignItems};`
      : "align-items: center;";

    propsStyles += props.justifyContent
      ? `justify-content: ${props.justifyContent};`
      : "justify-content: center;";

    return propsStyles;
  }}

  @media only screen and (max-width: 430px) {
    flex-direction: column;
  }
`;

const AboutStyled = styled.div`
  background-color: rgb(211, 216, 219);
  margin: 50px 0;

  img {
    margin-left: 50px;
  }

  @media only screen and (max-width: 430px) {
    padding: 0 20px;
    img {
      margin: 20px 0;
    }
  }
`;

const SubTitle = styled.h3`
  color: #575656;
  font-size: 32px;
  font-weight: 700;
  span {
    color: #d25148;
  }

  @media only screen and (max-width: 430px) {
    font-size: 28px;
  }
`;

const Text = styled.div`
  background-color: rgb(211, 216, 219);
`;

const Description = styled.p`
  color: #575656;
`;

const OurObjective = styled.div`
  background-color: rgb(211, 216, 219);
  margin-top: 80px;
  margin-bottom: 80px;

  p {
    color: #575656;
  }

  img {
    margin-left: 50px;
  }

  @media only screen and (max-width: 430px) {
    padding: 0 20px;

    img {
      margin-left: 0px;
    }
  }
`;

export {
  AboutStyled,
  Banner,
  Description,
  Flex,
  Message,
  OurObjective,
  OurStructure,
  SubTitle,
  Text,
  Title,
  TitleT,
};
