import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import WhatsYourDream from "../../Components/WhatsYourDream";
import { Banner, Title, Flex, Content } from "./styled";

const Privacy = () => {
  return (
    <>
      <Header />
      <Banner>
        <Flex>
          <Title>Políticas de Privacidade</Title>
        </Flex>
      </Banner>
      <Content className="__wrap">
        <p>
          Este documento tem por objetivo esclarecer os processos de dados
          pessoais coletados por meio do nosso website.
        </p>
        <p style={{ marginBottom: "50px" }}>
          Com o intuito de levar informação, esta Política de Privacidade foi
          redigida de forma clara e objetiva, para orientar quanto a segurança
          de fornecimento, utilização e compartilhamento de dados.
        </p>

        <h2>Aceite da Política</h2>
        <p>
          O aceite dessa Política ocorrerá quando o titular preencher os dados
          em algum formulário em nosso site, para acessar ou utilizar algum
          documento disponível, mesmo que gratuito. Desta forma, indicará que
          está ciente e de acordo com os termos e condições previstos neste
          documento.
        </p>
        <p style={{ marginBottom: "50px" }}>
          Caso não concorde com esta Política, por gentileza, não disponibilize
          seus dados e consequentemente não será possível utilizar nossos
          serviços. Entre em contato conosco através de nossos canais de
          comunicação informando os motivos do desacordo, para que possamos
          tomar as medidas necessárias e melhorar este documento.
        </p>

        <h2>Coleta de dados pessoais</h2>
        <p>
          Ao preencher os dados pessoais via preenchimento dos formulários para
          download dos nossos conteúdos e contato, a EMPRESA armazena os dados
          para interação e para posteriormente proporcionar conhecimentos
          através de conteúdos baseados nas experiências semelhantes e
          interesses inicialmente demonstrados.
        </p>
        <p style={{ marginBottom: "50px" }}>
          Se necessário alguns dados adicionais serão solicitados por e-mail ou
          telefone através de um colaborador da EMPRESA por motivos de
          interação, e ocasionalmente, para formalizar uma relação contratual,
          ou para o cumprimento de uma obrigação legal.
        </p>

        <h2>Proteção dos dados e informações</h2>
        <p style={{ marginBottom: "50px" }}>
          A EMPRESA se empenha em garantir a proteção e a confiabilidade dos
          dados pessoais de seus clientes e usuários ou aos quais venha a ter
          acesso ao website, de acordo com os fundamentos, princípios e demais
          disposições previstas na Lei Geral de Proteção de Dados e demais
          legislação aplicável.
        </p>

        <h2>Cookies</h2>
        <p>
          Em algumas páginas de nosso website, é inserido ‘cookies’ no seu
          navegador por meio do sistema Google Analytics e todo o processamento
          dos dados fornecidos são automáticos através de sistemas de IA. Ele
          identifica a quantidade de vezes que um visitante acessou alguns de
          nossos endereços eletrônicos.
        </p>
        <p style={{ marginBottom: "50px" }}>
          Esses ‘cookies’ são extraídos e possuem informações como endereço IP,
          localização geográfica, fonte de referência, tipo de navegador,
          duração da visita e páginas visitadas. Desta forma, podemos melhorar a
          qualidade e desempenho de nossos serviços, assim como combater
          fraudes, atendendo demais finalidades legítimas.
        </p>

        <h2>Armazenamento</h2>
        <p style={{ marginBottom: "50px" }}>
          A EMPRESA armazena as informações referente a contatos telefônicos ou
          e-mails já realizados, assim como quais os conteúdos acessados a
          partir dos preenchimentos de formulários nas nossas páginas.
        </p>

        <h2>Uso de dados pessoais</h2>
        <p>
          A EMPRESA utiliza os dados e preferências dos usuários registrados em
          nossas plataformas para proporcionar conhecimentos agradáveis,
          fornecer anúncios selecionados, visando sempre melhorar as
          experiências, atendendo especificamente os gostos.
        </p>
        <p>Detalhadamente:</p>
        <p>
          O e-mail fornecido é utilizado para a operação de envio de conteúdo ou
          material específicos na página acessada, através do preenchimento de
          formulário.
        </p>
        <p>
          O endereço de e-mail e telefone serão as fontes de comunicação para
          enviar e-mails sobre as novidades relacionados ao segmento da EMPRESA.
        </p>
        <p>
          Os dados de ações de visitantes das páginas são monitorados para criar
          perfis com base no comportamento, sendo qualificados por segmentações
          para simplificar os interesses de acordo com o perfil e
          posteriormente, para execução de ações de marketing direcionadas.
        </p>
        <p>
          Os colaboradores da EMPRESA poderão entrar em contato por e-mail ou
          telefone para possível levantamento de informações adicionais, assim
          como promover nossos serviços.
        </p>
        <p>
          Realizar publicidade e anúncios direcionados via e-mail ou pela
          internet, atendendo os dados compatíveis aos gostos, interesses e
          outras informações recolhidas.
        </p>
        <p>
          Analisar o desempenho das operações da EMPRESA para melhoria de nossos
          serviços, e ainda cumprir as obrigações legais assumidas pela EMPRESA
          com seus clientes e usuários.
        </p>
        <p>
          Todas as informações fornecidas são tratadas como confidenciais, e
          somente serão utilizadas para fins aqui descritos e previamente
          autorizados. Se eventualmente, ocorrer o uso de dados para outras
          finalidades que não cumpram com essa Política de Privacidade, será
          realizada uma autorização prévia do responsável.
        </p>
        <p style={{ marginBottom: "50px" }}>
          O uso dos dados poderá ser para qualquer fim quando autorizar ao
          fornecer os dados.v
        </p>

        <h2>Cancelamento e alteração/exclusão dos dados pessoais</h2>
        <p>
          Todos os e-mails enviados pela EMPRESA há sempre um link para cancelar
          sua inscrição, e ao acessá-lo será direcionado à uma página para
          confirmação do cancelamento.
        </p>
        <p>
          Após cancelado, todos os dados pessoais coletados são excluídos de
          nossos sistemas, salvo se houver algum cumprimento de obrigação legal
          e havendo novamente o preenchimento de qualquer formulário de nosso
          website.
        </p>
        <p>
          Em caso de um novo preenchimento dos dados em nosso website, os mesmos
          serão inseridos e armazenados, sendo necessária a solicitação do
          cancelamento de envio e exclusão dos dados novamente através do link
          disponível no e-mail.
        </p>
        <p style={{ marginBottom: "50px" }}>
          Poderá também solicitar a exclusão ou alteração dos dados a qualquer
          momento, através do e-mail{" "}
          <span style={{ color: "#d25148", fontWeight: "bold" }}>
            contato@freedom.com.br
          </span>{" "}
          , ou quando os dados não forem mais necessários para a finalidade
          proposta, salvo se houver outro motivo para a sua manutenção, como o
          cumprimento de uma obrigação legal ou a necessidade de preservação dos
          dados para assegurar o exercício de direitos da EMPRESA.
        </p>

        <h2>Mudanças na Política de Privacidade</h2>
        <p style={{ marginBottom: "50px" }}>
          Essa Política de Privacidade poderá ser alterada a qualquer momento,
          havendo necessidade para cumprir as finalidades previstas. É
          recomendável sempre consultar este documento para consentimento antes
          de fornecer os dados pessoais.
        </p>

        <h2>Lei Aplicável</h2>
        <p>
          Nossos contatos estão divulgados e ativos para receber qualquer
          sugestão, reclamação ou dúvida sobre sua privacidade, ou quaisquer
          direitos previstos na legislação.
        </p>
        <p style={{ marginBottom: "50px" }}>
          Este documento é regido e deve ser interpretado de acordo com as leis
          da República Federativa do Brasil.
        </p>
      </Content>
      <WhatsYourDream />
      <Footer />
    </>
  );
};

export default Privacy;
