import { useState } from "react";
import Select, { StylesConfig } from "react-select";
import { ReactComponent as Close } from "../../Assets/SVGs/Close.svg";
import { ReactComponent as Ap } from "../../Assets/SVGs/ap.svg";
import { ReactComponent as Casa } from "../../Assets/SVGs/casa.svg";
import { ReactComponent as Lote } from "../../Assets/SVGs/lote.svg";
import { ReactComponent as Shield } from "../../Assets/SVGs/shield.svg";
import { mascaraMetro, mascaraMoeda } from "../../Utils/masks";
import { SideBarFiltersStyle } from "./styled";

const SideBarFilters = (props) => {
  const { isOpen, setIsOpenFilters } = props;
  const { isCasa, setIsCasa } = props;
  const { isTerreno, setIsTerreno } = props;
  const { isApartamento, setIsApartamento } = props;
  const { isLancamento, setIsLancamento } = props;
  const { isMorar, setIsMorar } = props;
  const { isConstrucao, setIsConstrucao } = props;
  const { isCondo, setIsCondo } = props;
  const { minValue, setMinValue } = props;
  const { maxValue, setMaxValue } = props;
  const { minMeters, setMinMeters } = props;
  const { maxMeters, setMaxMeters } = props;
  const { quartosQtd, setQuartosQtd } = props;
  const { banheirosQtd, setBanheirosQtd } = props;
  const { vagasQtd, setVagasQtd } = props;
  const { dif, setDif } = props;
  const { location, setLocation } = props;

  const handleDiff = (value) => {
    if (dif.includes(value)) {
      setDif(dif.filter((item) => item !== value));
    } else {
      setDif([...dif, value]);
    }
  };

  const clearFilters = () => {
    setIsCasa(false);
    setIsTerreno(false);
    setIsApartamento(false);
    setIsLancamento(false);
    setIsMorar(false);
    setIsConstrucao(false);
    setIsCondo(false);
    setMinValue("");
    setMaxValue("");
    setMinMeters("");
    setMaxMeters("");
    setQuartosQtd(0);
    setBanheirosQtd(0);
    setVagasQtd(0);
    setDif([]);
    setLocation([]);
  };

  const styleSelect = {
    control: (base, state) => ({
      ...base,
      border: "2px solid #e7e7e7",
      padding: "8px 16px",
      borderRadius: "6px",
      paddingLeft: 0,
      "&:hover": {
        border: "2px solid #d25148",
      },
    }),
  };
  return (
    <SideBarFiltersStyle className={isOpen ? "open" : ""}>
      <div className="title">
        Todos os Filtros
        <Close onClick={() => setIsOpenFilters(false)} />
      </div>
      <div className="type-one">
        <p className="title">Tipo</p>
        <div className="inputs">
          <div
            onClick={() => setIsApartamento(!isApartamento)}
            className={isApartamento ? "__actived" : ""}
          >
            <Ap />
            <p>Apartamento</p>
          </div>
          <div
            onClick={() => setIsCasa(!isCasa)}
            className={isCasa ? "__actived" : ""}
          >
            <Casa />
            <p>Casa</p>
          </div>
          <div
            onClick={() => setIsTerreno(!isTerreno)}
            className={isTerreno ? "__actived" : ""}
          >
            <Lote />
            <p>Terreno</p>
          </div>
        </div>
        <label htmlFor="is-condo" className="is-condo">
          <Shield />
          Condomínio
          <label className="switch">
            <input
              id="is-condo"
              type="checkbox"
              checked={isCondo}
              onChange={() => setIsCondo(!isCondo)}
            />
            <span className="slider round"></span>
          </label>
        </label>
        <div className="divider"></div>
      </div>
      <div className="type-two">
        <p className="title">Localização</p>
        <Select
          className="select"
          placeholder="Filtrar por cidade"
          isMulti
          onChange={(ev) => setLocation(ev)}
          value={location}
          styles={styleSelect}
          options={[
            { value: "Itu", label: "Itu" },
            { value: "Salto", label: "Salto" },
            { value: "Indaiatuba", label: "Indaiatuba" },
          ]}
        />
        <div className="divider"></div>
      </div>
      <div className="type-three">
        <p className="title">Status</p>
        <div className="inputs">
          <div
            onClick={() => setIsLancamento(!isLancamento)}
            className={isLancamento ? "__actived" : ""}
          >
            <p>Lançamento</p>
          </div>
          <div
            onClick={() => setIsMorar(!isMorar)}
            className={isMorar ? "__actived" : ""}
          >
            <p>Pronto para morar</p>
          </div>
          <div
            onClick={() => setIsConstrucao(!isConstrucao)}
            className={isConstrucao ? "__actived" : ""}
          >
            <p>Em construção</p>
          </div>
        </div>
        <div className="divider"></div>
      </div>
      <div className="type-four">
        <p className="title">Valor R$</p>
        <div className="inputs">
          <label htmlFor="">
            <p>
              Min <span style={{ fontSize: "12px" }}>R$</span>
            </p>
            <input
              type="text"
              name="valor-min"
              value={minValue}
              onChange={(e) => setMinValue(mascaraMoeda(e.target))}
            />
          </label>
          <label htmlFor="">
            <p>
              Max <span style={{ fontSize: "12px" }}>R$</span>
            </p>
            <input
              type="text"
              name="valor-max"
              value={maxValue}
              onChange={(e) => setMaxValue(mascaraMoeda(e.target))}
            />
          </label>
        </div>
        <div className="divider"></div>
      </div>
      <div className="type-five">
        <p className="title">Metragem m²</p>
        <div className="inputs">
          <label htmlFor="">
            <p>Min</p>
            <input
              type="text"
              name="metro-min"
              value={minMeters}
              onChange={(e) => setMinMeters(mascaraMetro(e.target))}
            />
          </label>
          <label htmlFor="">
            <p>Max</p>
            <input
              type="text"
              name="metro-max"
              value={maxMeters}
              onChange={(e) => setMaxMeters(mascaraMetro(e.target))}
            />
          </label>
        </div>
        <div className="divider"></div>
      </div>
      <div className="type-six">
        <p className="title">Quartos</p>
        <div className="inputs">
          <div
            className={quartosQtd == 0 ? "__actived" : ""}
            onClick={() => setQuartosQtd(0)}
          >
            <p>Qualquer</p>
          </div>
          <div
            className={quartosQtd == 1 ? "__actived" : ""}
            onClick={() => setQuartosQtd(1)}
          >
            <p>1</p>
          </div>
          <div
            className={quartosQtd == 2 ? "__actived" : ""}
            onClick={() => setQuartosQtd(2)}
          >
            <p>2</p>
          </div>
          <div
            className={quartosQtd == 3 ? "__actived" : ""}
            onClick={() => setQuartosQtd(3)}
          >
            <p>3</p>
          </div>
          <div
            className={quartosQtd == 4 ? "__actived" : ""}
            onClick={() => setQuartosQtd(4)}
          >
            <p>4+</p>
          </div>
        </div>
        <div className="divider"></div>
      </div>
      <div className="type-seven">
        <p className="title">Banheiros</p>
        <div className="inputs">
          <div
            className={banheirosQtd == 0 ? "__actived" : ""}
            onClick={() => setBanheirosQtd(0)}
          >
            <p>Qualquer</p>
          </div>
          <div
            className={banheirosQtd == 1 ? "__actived" : ""}
            onClick={() => setBanheirosQtd(1)}
          >
            <p>1</p>
          </div>
          <div
            className={banheirosQtd == 2 ? "__actived" : ""}
            onClick={() => setBanheirosQtd(2)}
          >
            <p>2</p>
          </div>
          <div
            className={banheirosQtd == 3 ? "__actived" : ""}
            onClick={() => setBanheirosQtd(3)}
          >
            <p>3</p>
          </div>
          <div
            className={banheirosQtd == 4 ? "__actived" : ""}
            onClick={() => setBanheirosQtd(4)}
          >
            <p>4+</p>
          </div>
        </div>
        <div className="divider"></div>
      </div>
      <div className="type-eight">
        <p className="title">Vagas de garagem</p>
        <div className="inputs">
          <div
            className={vagasQtd == 0 ? "__actived" : ""}
            onClick={() => setVagasQtd(0)}
          >
            <p>Qualquer</p>
          </div>
          <div
            className={vagasQtd == 1 ? "__actived" : ""}
            onClick={() => setVagasQtd(1)}
          >
            <p>1</p>
          </div>
          <div
            className={vagasQtd == 2 ? "__actived" : ""}
            onClick={() => setVagasQtd(2)}
          >
            <p>2</p>
          </div>
          <div
            className={vagasQtd == 3 ? "__actived" : ""}
            onClick={() => setVagasQtd(3)}
          >
            <p>3</p>
          </div>
          <div
            className={vagasQtd == 4 ? "__actived" : ""}
            onClick={() => setVagasQtd(4)}
          >
            <p>4+</p>
          </div>
        </div>
        <div className="divider"></div>
      </div>
      <div className="type-nine">
        <p className="title">Diferenciais</p>
        <div className="inputs">
          {props.diferenciais.map((d) => (
            <div
              className={dif.includes(d) ? "__actived" : ""}
              onClick={() => handleDiff(d)}
            >
              {d}
            </div>
          ))}
        </div>
        <div className="divider"></div>
      </div>
      <div className="final">
        <div onClick={() => clearFilters()} className="clear">
          Limpar Filtros
        </div>
        <div
          className="filterbutton"
          onClick={() => {
            props.handleFilterButton();
            setIsOpenFilters(false);
          }}
        >
          Filtrar
        </div>
      </div>
    </SideBarFiltersStyle>
  );
};

export default SideBarFilters;
