import face from "../../Assets/Images/face.png";
import logo1 from "../../Assets/Images/freedom-brand-footer.png";
import insta from "../../Assets/Images/insta.png";
import whats from "../../Assets/Images/whatsapp.png";
import youtube from "../../Assets/Images/youtube.png";
import { Flex, FooterStyle } from "./styled";

const Footer = () => {
  return (
    <FooterStyle>
      <div className="__wrap">
        <img src={logo1} />
        <p>
          © {new Date().getFullYear()} Freedom Planejamento
          {/* CNPJ: 00.000.000/0001-00 */}
        </p>
        <Flex direction={"row"}>
          <a href="https://api.whatsapp.com/send?phone=5511934099014&text=Ol%C3%A1!%20eu%20vim%20atrav%C3%A9s%20do%20site%20de%20voc%C3%AAs.%20Quero%20come%C3%A7ar%20a%20planejar%20meu%20sonho%20com%20a%20Freedom!">
            <Flex direction={"row"}>
              <img src={whats} style={{ marginRight: "10px" }} />
              <p className="zap">(11) 93409-9014</p>
            </Flex>
          </a>
          <a href="">
            <img src={face} />
          </a>
          <a href="">
            <img src={insta} />
          </a>
          <a href="">
            <img src={youtube} />
          </a>
        </Flex>
      </div>
    </FooterStyle>
  );
};

export default Footer;
