import axios from "axios";
import { useEffect, useState } from "react";
import partner2 from "../../Assets/Images/homi1Bg.png";
import partner3 from "../../Assets/Images/homi2Bg.png";
import partner1 from "../../Assets/Images/juBg.png";
import {
  Bullet,
  Bullets,
  Cargo,
  Description,
  Item,
  ItemsSlider,
  Name,
  PartnersStyle,
  Text,
} from "./styled";

const Partners = () => {
  const [actived, setActived] = useState(0);
  const [partnersPeople, setPartnersPeople] = useState([]);

  useEffect(() => {
    axios
      .get("https://freedomplanejamento.com.br/painel/wp-json/acf/v3/posts", {
        // headers: {
        //   "X-WP-Nonce": "wp_rest",
        // },
      })
      .then((response) => {
        const teamPeople = response.data.filter(
          (person) => person["acf"]["socio"][0] == "Sim"
        );
        setPartnersPeople(teamPeople);
      });
  }, []);

  const handlerActived = (number) => {
    setActived(number);
  };

  useEffect(() => {
    const myTimer = setInterval(() => {
      setActived((prevTime) =>
        prevTime >= partnersPeople.length - 1 ? 0 : prevTime + 1
      );
    }, 5000);
    return () => clearInterval(myTimer);
  }, [partnersPeople]);

  return (
    <PartnersStyle>
      <div className="__wrap">
        <ItemsSlider>
          <Bullets>
            {partnersPeople.map((person, index) => (
              <Bullet
                key={person.id}
                onClick={() => {
                  handlerActived(index);
                }}
                className={actived == index ? "_actived" : ""}
              ></Bullet>
            ))}
          </Bullets>
          {partnersPeople.map((person, index) => (
            <Item
              key={person.id}
              className={actived == index ? "_actived" : ""}
            >
              <img src={person["acf"]["foto_do_funcionario"]} />
              <Text>
                <Name>{person["acf"]["nome"]}</Name>
                <Cargo>{person["acf"]["cargo"]}</Cargo>
                <Description>{person["acf"]["descricao"]}</Description>
              </Text>
            </Item>
          ))}
        </ItemsSlider>
      </div>
    </PartnersStyle>
  );
};

export default Partners;
