import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as Ap } from "../../Assets/SVGs/ap.svg";
import { ReactComponent as Casa } from "../../Assets/SVGs/casa.svg";
import { ReactComponent as Location } from "../../Assets/SVGs/location.svg";
import { ReactComponent as Lote } from "../../Assets/SVGs/lote.svg";
import { ReactComponent as NoImage } from "../../Assets/SVGs/no-image2.svg";
import { ReactComponent as Shield } from "../../Assets/SVGs/shield.svg";

import States from "../../Common/states";
import { Circle, Datas, Flex, ImageBuild, SeeMoreCard } from "./styled";

const BuildCard = (props) => {
  const tituloAnuncio = useRef();

  const handleTipo = () => {
    if (props.imovel.attributes.Tipo == "Apartamento") {
      return <Ap />;
    } else if (props.imovel.attributes.Tipo == "Terreno") {
      return <Lote />;
    } else {
      return <Casa />;
    }
  };

  function strToURL(str) {
    return str
      .toLowerCase()
      .trim()
      .replace(/[áàãâä]/g, "a")
      .replace(/[éèẽêë]/g, "e")
      .replace(/[íìĩîï]/g, "i")
      .replace(/[óòõôö]/g, "o")
      .replace(/[úùũûü]/g, "u")
      .replace(/ç/g, "c")
      .replace(/(\ |_)+/, " ")
      .replace(/(^-+|-+$)/, "")
      .replace(/[^a-z0-9]+/g, "-");
  }
  // Caso queira randomizar a tela principal props.imovel.imagens[Math.floor(Math.random() * 2)].link
  return (
    <div className="BuildCard">
      <Link
        to={`${strToURL(
          (typeof props.imovel.attributes.Condominio_Fechado !== "undefined"
            ? "imov"
            : "condo") +
            "-" +
            props.imovel.attributes.Nome +
            "-" +
            props.imovel.id
        )}`}
      >
        <ImageBuild
          style={{
            // backgroundSize: "cover",
            backgroundImage: `url(${
              props.imovel.attributes.Fotos.data[0].attributes.url
                ? "https://painel.freedomplanejamento.com.br" +
                  props.imovel.attributes.Fotos.data[0].attributes.url
                : ""
            })`,
          }}
        >
          {props.imovel.attributes.Fotos.data[0].attributes.url == undefined ? (
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                  height: "100%",
                }}
              >
                <NoImage
                  style={{
                    width: "50px",
                    fill: "rgb(165, 165, 168)",
                    alignSelf: "center",
                  }}
                />
                <p
                  style={{
                    color: "rgb(165, 165, 168)",
                    alignSelf: "center",
                    zIndex: "0",
                  }}
                >
                  SEM FOTO
                </p>
              </div>
            </>
          ) : (
            ""
          )}
          <p
            ref={tituloAnuncio}
            style={{
              transform: `translateY(${
                tituloAnuncio?.current?.clientHeight > 30
                  ? 130 - tituloAnuncio?.current?.clientHeight
                  : 135
              }%)`,
            }}
          >
            {props.imovel.attributes.Nome}{" "}
          </p>
          <span>{props.imovel.attributes.Status}</span>
          <SeeMoreCard className="seeMore">Veja Mais</SeeMoreCard>
        </ImageBuild>
        <Datas>
          <Flex direction={"row"} justifyContent={"flex-start"}>
            {handleTipo()}
            <div style={{ marginRight: "5px" }}></div>
            {props.imovel.attributes.Tipo}
            <div style={{ marginRight: "20px" }}></div>
            {props.imovel.attributes.Condominio_Fechado == true && (
              <>
                <Shield />
                <p style={{ marginLeft: "10px" }}>Condomínio Fechado</p>
              </>
            )}
          </Flex>
          <div style={{ marginTop: "10px" }}></div>
          {props.imovel.attributes.Mostrar_Preco !== false && (
            <p style={{ fontWeight: "bold", fontSize: "22px" }}>
              R$ {props.imovel.attributes.Valor}
            </p>
          )}
          <div style={{ marginTop: "10px" }}></div>
          <Flex direction={"row"} justifyContent={"flex-start"}>
            <Location
              style={{
                fill: "#575656",
                marginLeft: "4px",
                marginRight: "10px",
              }}
            />
            {props.imovel.attributes.Cidade.length +
              props.imovel.attributes.Estado.length +
              (props.imovel.attributes.Endereco
                ? props.imovel.attributes.Endereco.Bairro.length
                : 0) >
            35 ? (
              <>
                {/* {props.imovel.endereco_cidade}/{States()[estado]} */}
                {props.imovel.attributes.Cidade}/
                {props.imovel.attributes.Estado}
                <Circle />
                {props.imovel.attributes.Endereco
                  ? props.imovel.attributes.Endereco.Bairro.length
                  : (props.imovel.attributes?.Endereco.Bairro.length > 25
                      ? props.imovel.attributes?.Endereco.Bairro.slice(0, -8)
                      : props.imovel.attributes?.Endereco.Bairro.slice(0, -2)) +
                    "..."}
              </>
            ) : (
              <>
                {props.imovel.attributes.Cidade}/
                {props.imovel.attributes.Estado}
                <Circle />
                {props.imovel.attributes?.Endereco
                  ? props.imovel.attributes?.Endereco.Bairro
                  : ""}
              </>
            )}
            {/* {props.imovel.attributes.Cidade}/{props.imovel.attributes.Estado}
            <Circle />
            {props.imovel.attributes.Endereco.Bairro} */}
          </Flex>
        </Datas>
      </Link>
    </div>
  );
};

export default BuildCard;
