import { useRef, useState } from "react";
import logo1 from "../../Assets/SVGs/brand.svg";
import logo2 from "../../Assets/SVGs/brand2.svg";
import { HeaderStyle, NavLink, NavStyle } from "./styled";

import { Link } from "react-router-dom";
import Button from "../Buttons";
import MenuButton from "../MenuButton";

const Header = () => {
  const [paddingTb, setPaddingTb] = useState("");
  const [isOpen, setOpen] = useState(false);
  const scrollHeader = useRef(null);

  window.addEventListener("scroll", () => {
    const scrollHeaderResult = scrollHeader.current;
    if (scrollHeaderResult) {
      const result =
        window.pageYOffset >= 100
          ? scrollHeaderResult.classList.add("_actived") & setPaddingTb("8px")
          : scrollHeaderResult.className.includes("_actived")
          ? scrollHeaderResult.classList.remove("_actived") & setPaddingTb("")
          : "";
    }
  });

  return (
    <HeaderStyle ref={scrollHeader}>
      <div
        className="__wrap _scroll"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Link to={"/"}>
          <img
            src={
              window.screen.width > 428
                ? paddingTb != ""
                  ? logo2
                  : logo1
                : logo2
            }
            alt="Logo Freedom"
          />
        </Link>
        <NavStyle className={isOpen ? "__opened" : ""}>
          <NavLink>
            <Link to="/sobre">Conheça a Freedom</Link>
          </NavLink>
          <NavLink>
            <Link to="/empreendimentos">Empreendimentos</Link>
          </NavLink>
          <NavLink>
            <Link to="/contato">Contato</Link>
          </NavLink>

          <a
            href="https://api.whatsapp.com/send?phone=5511934099014&text=Ol%C3%A1!%20eu%20vim%20atrav%C3%A9s%20do%20site%20de%20voc%C3%AAs.%20Quero%20come%C3%A7ar%20a%20planejar%20meu%20sonho%20com%20a%20Freedom!"
            style={{ marginLeft: 48 }}
            target="_blank"
            rel="noreferrer"
          >
            <Button paddingTb={paddingTb}>Realize seu sonho</Button>
          </a>
        </NavStyle>
        {window.screen.width < 480 ? (
          <MenuButton
            isOpen={isOpen}
            onClick={() => setOpen(!isOpen)}
            strokeWidth="5"
            color="#D25148"
            lineProps={{ strokeLinecap: "round" }}
            className="MenuButton"
            transition={{ type: "spring", stiffness: 260, damping: 20 }}
            width="24"
            height="24"
          />
        ) : (
          ""
        )}
      </div>
    </HeaderStyle>
  );
};

export default Header;
