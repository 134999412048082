import emailjs from "@emailjs/browser";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import TelefoneBrasileiroInput from "react-telefone-brasileiro";
import email from "../../Assets/Images/emailred.png";
import face from "../../Assets/Images/facebookwhite.png";
import insta from "../../Assets/Images/instawhite.png";
import point from "../../Assets/Images/pointred.png";
import you from "../../Assets/Images/youtubewhite.png";
import zap from "../../Assets/Images/zapred.png";
import Button from "../../Components/Buttons";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import {
  Banner,
  Description,
  Flex,
  Form,
  FormDiv,
  Icons,
  Title,
  TitleT,
} from "./styled";

const Contact = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [isAp, setIsAp] = useState(false);
  const [isLot, setIsLot] = useState(false);
  const [isCasa, setIsCasa] = useState(false);
  const [isPriva, setIsPriva] = useState(false);
  const [telefone, setTelefone] = useState("");
  const [emailInput, setEmail] = useState("");
  const [nameUser, setNameUser] = useState("");
  const [message, setMessage] = useState("");
  // const [dreamCompleto, setDreamCompleto] = useState("");

  const handleOnChangeAp = () => {
    setIsAp(!isAp);
  };
  const handleOnChangeLot = () => {
    setIsLot(!isLot);
  };
  const handleOnChangeCasa = () => {
    setIsCasa(!isCasa);
  };
  const handleOnChangePriva = () => {
    setIsPriva(!isPriva);
  };
  const handleOnChangeName = (ev) => {
    setNameUser(ev.target.value);
  };
  const handleOnChangeEmail = (ev) => {
    setEmail(ev.target.value);
  };
  const handleOnChangePhone = (ev) => {
    setTelefone(ev.target.value);
  };
  const handleOnChangeMessage = (ev) => {
    setMessage(ev.target.value);
  };

  const sendEmail = () => {
    let dreamCompleto = "";

    if (isAp && isLot && isCasa) {
      dreamCompleto = "Casa, um Apartamento ou um Terreno";
    } else if (!isAp && !isLot && isCasa) {
      dreamCompleto = "Casa";
    } else if (!isAp && isLot && !isCasa) {
      dreamCompleto = "Terreno";
    } else if (isAp && !isLot && !isCasa) {
      dreamCompleto = "Apartamento";
    } else {
      const haveEmp = [];
      const a = isCasa ? haveEmp.push("Casa") : "";
      const b = isAp ? haveEmp.push("Apartamento") : "";
      const c = isLot ? haveEmp.push("Terreno") : "";
      dreamCompleto = haveEmp.join(" ou ");
    }

    const button = document.querySelector(".subtn");
    button.textContent = "Enviando...";
    button.classList.add("__actived");

    setTimeout(() => {
      window.location.replace(
        `https://api.whatsapp.com/send?phone=5511934099014&text=Ol%C3%A1!%20Me%20chamo%20${nameUser}%20e%20vim%20atrav%C3%A9s%20do%20site%20de%20voc%C3%AAs.%20Quero%20come%C3%A7ar%20a%20planejar%20meu%20sonho%20de%20um(a)%20${dreamCompleto}%20com%20a%20Freedom!`
      );
      // emailjs
      //   .send(
      //     "hostinger",
      //     "template1",
      //     {
      //       subject: `Olá Freedom! Tenho um sonho de ter meu lar!`,
      //       name: nameUser,
      //       email: emailInput,
      //       phone: telefone,
      //       typeEmp: `
      //     Meu sonho é ${isCasa ? " uma " : " um "} ${dreamCompleto}`,
      //       message: message,
      //     },
      //     "APyoPH2-Db_Tq8M8j"
      //   )
      //   .then((resp) => {
      //     if (resp.status === 200) {
      //       alert("Mensagem enviada com sucesso!");
      //       setIsAp(false);
      //       setIsLot(false);
      //       setIsCasa(false);
      //       setIsPriva(false);
      //       setTelefone("");
      //       setEmail("");
      //       setNameUser("");
      //       setMessage("");
      //       button.textContent = "Enviar Contato";
      //       button.classList.remove("__actived");
      //     } else {
      //       alert("Mensagem não enviada");
      //     }
      //   });
    }, 1000);
  };

  return (
    <>
      <Header />
      <Banner>
        <div className="__wrap">
          <Flex>
            <Title>
              <Flex>
                <span>Contato</span>
                <h2>
                  Nosso atendimento é extremamente personalizado e focado em
                  <span> realizar seu sonho</span>.
                </h2>
              </Flex>
            </Title>
          </Flex>
        </div>
      </Banner>
      <FormDiv>
        <div className="__wrap">
          <Flex
            className="mobile-column"
            direction={"row"}
            justifyContent={"space-between"}
          >
            <Flex justifyContent={"space-between"} alignItems={"flex-start"}>
              <TitleT>
                Como podemos <br />
                <span>te ajudar?</span>
              </TitleT>
              <Description>
                Preencha o formulário ao lado que logo entraremos
                <br /> em contato ou fale com a gente por um de nossos
                <br /> canais de atendimento.
              </Description>
              <Icons>
                <Flex direction={"row"}>
                  <img src={zap} />
                  (11) 93409-9014
                </Flex>
              </Icons>
              <Icons>
                <Flex direction={"row"}>
                  <img src={email} />
                  contato@freedom.com.br
                </Flex>
              </Icons>
              <Icons>
                <Flex direction={"row"}>
                  <img src={point} />
                  Av. Dom Pedro II, 47, Centro, Salto - SP
                </Flex>
              </Icons>
              <Icons>
                <Flex alignItems={"flex-start"}>
                  <p
                    style={{
                      fontSize: "22px",
                      fontWeight: "500",
                      marginTop: "50px",
                    }}
                  >
                    <span style={{ color: "#d25148" }}>Siga a Freedom </span>nas
                    redes sociais!
                  </p>
                  <Flex direction={"row"}>
                    <a
                      href="https://www.facebook.com/freedomplanejamento"
                      target={"_blank"}
                      rel="noreferrer"
                    >
                      <img src={face} />
                    </a>
                    <a
                      href="https://www.instagram.com/freedom.imobiliaria/"
                      target={"_blank"}
                      rel="noreferrer"
                    >
                      <img src={insta} />
                    </a>
                    {/* <a href="" target={"_blank"}>
                      <img src={you} />
                    </a> */}
                  </Flex>
                </Flex>
              </Icons>
            </Flex>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                sendEmail();
              }}
            >
              <label>
                <Flex direction={"row"}>
                  <legend>Nome: </legend>
                  <input
                    type="text"
                    name="name"
                    value={nameUser}
                    onChange={(ev) => handleOnChangeName(ev)}
                    placeholder="Obrigatório"
                    required
                  />
                </Flex>
              </label>
              {/* <label>
                <Flex direction={"row"}>
                  <legend>Telefone: </legend>
                  <TelefoneBrasileiroInput
                    type="tel"
                    name="phone"
                    placeholder="Obrigatório"
                    value={telefone}
                    onChange={(ev) => handleOnChangePhone(ev)}
                    temDDD
                    required
                  />
                </Flex>
              </label>
              <label>
                <Flex direction={"row"}>
                  <legend>E-mail: </legend>
                  <input
                    name="email"
                    type="email"
                    value={emailInput}
                    onChange={(ev) => handleOnChangeEmail(ev)}
                    placeholder="Obrigatório"
                    required
                  />
                </Flex>
              </label> */}
              <div className="checks">
                <Flex direction={"row"} justifyContent={"space-between"}>
                  <legend>Qual seu sonho?: </legend>
                  <Flex alignItems={"flex-end"}>
                    <Flex direction={"row"} justifyContent={"space-between"}>
                      <p style={{ color: "#575656" }}>Apartamento</p>
                      <input
                        type="checkbox"
                        id="tipoEmpreend"
                        name="tipoEmpreend[]"
                        value="Apartamento"
                        checked={isAp}
                        onChange={handleOnChangeAp}
                      />
                    </Flex>
                    <Flex direction={"row"} justifyContent={"space-between"}>
                      <p style={{ color: "#575656" }}>Casa</p>
                      <input
                        type="checkbox"
                        id="tipoEmpreend"
                        name="tipoEmpreend[]"
                        value="Casa"
                        checked={isCasa}
                        onChange={handleOnChangeCasa}
                      />
                    </Flex>
                    <Flex direction={"row"} justifyContent={"space-between"}>
                      <p style={{ color: "#575656" }}>Terreno</p>
                      <input
                        type="checkbox"
                        id="tipoEmpreend"
                        name="tipoEmpreend[]"
                        value="Terreno"
                        checked={isLot}
                        onChange={handleOnChangeLot}
                      />
                    </Flex>
                  </Flex>
                </Flex>
              </div>

              {/* <label>
                <Flex direction={"row"}>
                  <legend>Mensagem: </legend>
                  <textarea
                    name="message"
                    id="message"
                    value={message}
                    onChange={(ev) => handleOnChangeMessage(ev)}
                  />
                </Flex>
              </label> */}
              <label className="privacidade">
                <Flex direction={"row"} justifyContent={"flex-end"}>
                  <legend>
                    Concordo em receber comunicação por email de
                    <br /> acordo com a{" "}
                    <Link to={"/politica-de-privacidade"}>
                      Política de Privacidade
                    </Link>{" "}
                    da Freedom{" "}
                  </legend>
                  <input
                    type="checkbox"
                    id="privacidade"
                    name="privacidade"
                    value="Aceito"
                    checked={isPriva}
                    onChange={handleOnChangePriva}
                    required
                  />
                </Flex>
              </label>
              <Button className="subtn" type="submit">
                Enviar Contato
              </Button>
            </Form>
          </Flex>
        </div>
      </FormDiv>
      <Footer />
    </>
  );
};

export default Contact;
