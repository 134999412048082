import { useState } from "react";
import { ItemStyle, ContainerSlider } from "./styled";

const Slidershow = (props) => {
  const [sliderActive, setSliderActive] = useState(0);

  setInterval(() => {
    if (props.backgrounds.length - 1 === sliderActive) {
      setSliderActive(0);
    } else {
      setSliderActive(sliderActive + 1);
    }
  }, 6000);

  return (
    <ContainerSlider>
      {props.children}
      {props.backgrounds.map((item, index) => (
        <ItemStyle
          key={index}
          style={{ backgroundImage: `url(${item})` }}
          className={sliderActive === index ? "active" : ""}
        ></ItemStyle>
      ))}
    </ContainerSlider>
  );
};

export default Slidershow;
