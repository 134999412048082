import styled from "styled-components";

const Banner = styled.div`
  background-color: #181818;
  height: 50vh;
  padding-top: 40px;

  @media only screen and (max-width: 430px) {
    padding-left: 20px;
    padding-right: 20px;
    font-size: 12px;
  }

  .__wrap {
    height: 100%;
  }

  svg {
    fill: #d25148;
    margin: 0 10px 0 4px;
  }

  .tipo {
    margin-bottom: 10px;

    svg {
      margin: 0 5px 0 0px;
      fill: transparent !important;
      path,
      line {
        stroke: #d25148;
      }
    }
  }

  p {
    color: white;
  }
`;

const Flex = styled.div`
  display: flex;

  ${(props) => {
    let propsStyles = props.direction
      ? `flex-direction: ${props.direction};`
      : "flex-direction: row;";

    propsStyles += props.alignItems
      ? `align-items: ${props.alignItems};`
      : "align-items: center;";

    propsStyles += props.justifyContent
      ? `justify-content: ${props.justifyContent};`
      : "justify-content: center;";

    propsStyles += props.flexWrap
      ? `flex-wrap: ${props.flexWrap};`
      : "flex-wrap: no-wrap;";

    propsStyles += props.heights ? `height: ${props.heights};` : "";

    return propsStyles;
  }}

  .imagensIcones {
    border: 2px solid #575656;
    padding: 8px 16px;
    border-radius: 5px;
    color: #575656;
    font-size: 16px;
    font-weight: 500;
    background: transparent;
    transition: 0.3s background ease, 0.3s color ease, 0.3s border ease;
    cursor: pointer;
    margin-right: 15px;

    &:hover,
    &._actived {
      background: #d25148;
      color: white;
      border: 2px solid #d25148;

      svg {
        fill: white;
      }
    }

    svg {
      margin-right: 10px;
      fill: #575656;
      transition: 0.3s fill ease;
    }
  }

  @media only screen and (max-width: 430px) {
    &.mobile-column {
      flex-direction: column;
    }
  }
`;

const Etapa = styled.div`
  font-weight: bold;
  background-color: #d25148;
  color: white;
  z-index: 1;
  top: 20px;
  font-size: 14px;
  padding: 5px;
  border-radius: 4px;
  margin-bottom: 20px;
`;

const Title = styled.div`
  color: white;
  font-weight: 500;
  font-size: 36px;
  margin-bottom: 20px;
`;

const ImagesShow = styled.div`
  .ZoomImgs {
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.8);
    width: 100%;
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    opacity: 0;
    cursor: zoom-out;
    visibility: hidden;
    transition: visibility 0.1s 0.4s ease, opacity 0.4s ease;

    &._actived {
      opacity: 1;
      visibility: visible;
      transition: visibility 0.1s ease, opacity 0.3s 0.2s ease;
    }

    img {
      width: auto;
      height: 95%;

      @media only screen and (max-width: 430px) {
        width: 90%;
        height: auto;
      }
    }
  }

  .Carousel {
    overflow: hidden;
    cursor: zoom-in;
    background: white;
    position: relative;
    height: 480px;

    @media only screen and (max-width: 430px) {
      height: 380px;
    }

    &:after {
      content: "";
      height: 100%;
      width: 100px;
      background: linear-gradient(to right, transparent, rgba(0, 0, 0, 0.9));
      display: block;
      position: absolute;
      top: 0;
      right: 0;

      @media only screen and (max-width: 430px) {
        right: -30px;
      }
    }

    &:before {
      content: "";
      height: 100%;
      width: 100px;
      background: linear-gradient(to left, transparent, rgba(0, 0, 0, 0.9));
      display: block;
      position: absolute;
      top: 0;
      left: 0;

      @media only screen and (max-width: 430px) {
        left: -30px;
      }
    }

    .inner {
      display: flex;
      height: 100%;

      .item {
        width: 630px;
        height: 100%;
        background-color: #181818;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;

        &:last-child {
          width: 780px;
        }
      }
    }
  }
`;

const RightArrow = styled.div`
  position: absolute;
  top: 50%;
  right: 45px;
  width: 15px;
  height: 15px;
  border-top: 4px solid white;
  border-right: 4px solid white;
  transform: rotate(45deg) scale(1) translateY(-50%);
  cursor: pointer;
  transition: transform 0.5s ease;
  z-index: 10;

  &:hover {
    transform: rotate(45deg) scale(1.15) translateY(-50%);
  }
`;

const LeftArrow = styled.div`
  position: absolute;
  top: 50%;
  left: 45px;
  width: 15px;
  height: 15px;
  height: 15px;
  border-top: 4px solid white;
  border-left: 4px solid white;
  transform: rotate(-45deg) scale(1) translateY(-50%);
  cursor: pointer;
  z-index: 10;
  transition: transform 0.5s ease;

  &:hover {
    transform: rotate(-45deg) scale(1.15) translateY(-50%);
  }
`;

const Alt = styled.div`
  height: 480px;

  .leaflet-container {
    height: 100%;
    width: 100%;
  }

  @media only screen and (max-width: 430px) {
    height: 380px;
  }
`;

const Contents = styled.div`
  padding-top: 50px;
  border-top: 2px solid #e7e7e7;
  margin-top: 50px;
  width: 65%;

  @media only screen and (max-width: 430px) {
    width: 100%;
  }
`;

const BackgroundPage = styled.div`
  background: linear-gradient(to right, white 65%, #f0f0f0 50%);

  @media only screen and (max-width: 430px) {
    background: linear-gradient(to bottom, white 65%, #f0f0f0 50%);

    .mobile-wrap {
      padding: 0 20px;
    }
  }

  .ZoomPlantas {
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.8);
    width: 100%;
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    opacity: 0;
    cursor: zoom-out;
    visibility: hidden;
    transition: visibility 0.1s 0.4s ease, opacity 0.4s ease;

    &._actived {
      opacity: 1;
      visibility: visible;
      transition: visibility 0.1s ease, opacity 0.3s 0.2s ease;
    }

    img {
      width: auto;
      height: 95%;

      @media only screen and (max-width: 430px) {
        height: auto;
        width: 90%;
      }
    }
  }
`;

const SobreImoveis = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: black;
  margin-bottom: 50px;

  @media only screen and (max-width: 430px) {
    .mobile-row {
      flex-direction: row;
    }
  }

  .items {
    width: 100%;
    margin: 20px 0;

    @media only screen and (max-width: 430px) {
      flex-direction: column;
    }

    .item {
      width: 33%;

      @media only screen and (max-width: 430px) {
        width: 100%;
        font-size: 12px;
        flex-direction: column;
        margin: 10px 0;
        text-align: center;
      }

      &:nth-child(2n) {
        width: 30%;
        margin-left: 5%;
        margin-right: 5%;

        @media only screen and (max-width: 430px) {
          width: 100%;
          margin: 0;
        }
      }
    }
  }

  svg {
    margin-right: 10px;
  }
`;

const Videos = styled.div`
  h3 {
    color: black;
    font-size: 22px;
    font-weight: 600;
  }

  iframe {
    width: 100%;
    height: 400px;
    border-radius: 20px;
    margin-bottom: 50px;
  }
`;
const Infra = styled.div`
  margin-bottom: 50px;

  h3 {
    color: black;
    font-size: 22px;
    font-weight: 600;
  }

  .items {
    width: 33%;
    margin-bottom: 20px;

    @media only screen and (max-width: 430px) {
      width: 50%;
      font-size: 12px;
    }

    svg {
      margin-right: 10px;
    }
  }

  ul li {
    font-size: 16px;
    color: black;
    span {
      font-size: 14px;
      color: #7a7676;
    }
  }
`;

const Plantas = styled.div`
  margin-bottom: 50px;
  h3 {
    color: black;
    font-size: 22px;
    font-weight: 600;
  }

  .plantaMain {
    width: 100%;
    height: 480px;
    background-color: transparent;
    margin-bottom: 30px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    cursor: zoom-in;
  }

  .plantasCounters {
    display: flex;
    width: 100%;
    overflow: auto;
    flex-wrap: wrap;

    .counter {
      margin-right: 20px;
      margin-bottom: 20px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      height: 85px;
      width: 85px;
      cursor: pointer;
      position: relative;
      border: 2px solid #d25148;
      border-radius: 5px;

      &:hover,
      &._actived {
        &:after {
          opacity: 0;
        }
      }

      &:after {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0.5;
        background-color: black;
        transition: opacity 0.4s ease;
      }
    }
  }
`;

const Separetor = styled.span`
  width: 100%;
  height: 2px;
  background-color: #e7e7e7;
  display: block;
  margin: 10px 0 40px 0;
`;

const Form = styled.form`
  position: sticky;
  display: flex;
  top: 100px;
  right:0px;
  padding: 30px;
  align-items: flex-end;
  justify-content: center;
  flex-direction: column;
  background-color: white;
  width: 40%;
  margin-left: 20px;
  height: 30%;
  box-shadow: 0 0 30px 2px rgba(0, 0, 0, 0.15);
  border-radius: 10px;

  @media only screen and (max-width: 430px) {
    position: static;
    width: 100%;
    margin-left: 0;
    margin-top: 20px;
  }

  .titulo-form {
    font-size: 22px;
    font-weight: bold;
    line-height: 25px;
    align-self: flex-start;
    margin-bottom: 20px;
    color: #181818;
    span {
      color #d25148;
      

    }
  }

  label:not(.privacidade), .checks {
    width: 100%;
    margin-bottom: 30px;

    legend {
      font-size: 22px;
      font-weight: 600;
      color: #c4c4c4;
      margin-right: 20px;
    }
    input,
    textarea {
      border-bottom: 2px solid #c4c4c4;
      text-align: right;
      font-weight: 600;
      color: #181818;
      width: 70%;
      height: 20px;
    }

    input[type="checkbox"] {
      width 16px;
      height 16px;
      border: 2px solid #c4c4c4;
      border-radius: 2px;
      position: relative;
      cursor: pointer;
      margin-left: 10px;
      transition: 0.2s background-color ease, 0.2s border ease;

      &:checked {
        background-color: #d25148;
        border: 2px solid #d25148;

        &:after {
          opacity: 1;
        }
      }

      &:after {
        content: '';
        width: 5px;
        height: 8px;
        display: block;
        border-right: 2px solid white;
        border-bottom: 2px solid white;
        position: absolute;
        top: 35%;
        left: 50%;
        transition: 0.2s opacity ease;
        opacity: 0;
        transform: translate(-50%, -50%) rotate(35deg);

      } 

    }
    
  }
  .privacidade {
    width: 100%;
    margin-bottom: 20px;

    a {
      text-decoration: underline;
      transition: color 0.2s ease;

      &:hover {
        color: #d25148;
      }
    }

    legend {
      font-size: 12px;
      text-align: right;
      color: #575656;
    }

    input[type="checkbox"] {
      width 16px;
      height 16px;
      cursor: pointer;
      border: 2px solid #c4c4c4;
      border-radius: 2px;
      position: relative;
      margin-left: 10px;
      transition: 0.2s background-color ease, 0.2s border ease;

      &:checked {
        background-color: #d25148;
        border: 2px solid #d25148;

        &:after {
          opacity: 1;
        }
      }

      &:after {
        content: '';
        width: 5px;
        height: 8px;
        display: block;
        border-right: 2px solid white;
        border-bottom: 2px solid white;
        position: absolute;
        top: 35%;
        left: 50%;
        transition: 0.2s opacity ease;
        opacity: 0;
        transform: translate(-50%, -50%) rotate(35deg);

      } 
  }
`;

export {
  Alt,
  BackgroundPage,
  Banner,
  Contents,
  Etapa,
  Flex,
  Form,
  ImagesShow,
  Infra,
  LeftArrow,
  Plantas,
  RightArrow,
  Separetor,
  SobreImoveis,
  Title,
  Videos,
};
