import styled from "styled-components";

const ContentTypesBuildingVentures = styled.section`
  padding: 120px 0;

  @media only screen and (max-width: 430px) {
    padding: 60px 20px;
  }
`;

const SecondTitle = styled.h2`
  font-size: 36px;
  color: #575656;
  font-weight: 500;
  margin-bottom: 60px;

  @media only screen and (max-width: 430px) {
    line-height: 35px;
  }
`;

const ContentTypeCard = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media only screen and (max-width: 430px) {
    flex-direction: column;
  }
`;

const Card = styled.div`
  position: relative;
  height: 416px;
  width: 30%;
  border-radius: 16px;
  background-color: rgba(0, 0, 0, 1);
  overflow: hidden;

  @media only screen and (max-width: 430px) {
    width: 100%;
    height: 350px;
    margin-bottom: 30px;
  }

  &:hover {
    div {
      transform: translateY(0%);
    }
    p {
      transform: translateY(0%);
    }
  }

  a {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
    width: 100%;
  }
`;

const TextsCard = styled.div`
  background-color: #D25148;
  padding: 20px;
  width 100%;
  font-size: 22px;
  color: white;
  font-weight: 500;
  transform: translateY(35%);
  transition: transform 0.4s ease;
`;

const SeeMoreCard = styled.p`
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  font-weight: 400;
  margin-top: 10px;
  transform: translateY(35%);
  transition: transform 0.4s ease;

  &:after {
    content: "";
    width: 6px;
    height: 6px;
    border-top: 2px solid;
    border-right: 2px solid;
    transform: rotate(45deg) translate(5px, -5px);
  }
`;

export {
  Card,
  ContentTypeCard,
  ContentTypesBuildingVentures,
  SecondTitle,
  SeeMoreCard,
  TextsCard,
};
