import { Flex, SobreImoveis } from "./styled";

import { ReactComponent as Senha } from "../../Assets/SVGs/Senha.svg";
import { ReactComponent as Banheiros } from "../../Assets/SVGs/banheiro.svg";
import { ReactComponent as Cama } from "../../Assets/SVGs/cama.svg";
import { ReactComponent as Planta } from "../../Assets/SVGs/planta.svg";
import { ReactComponent as Vagas } from "../../Assets/SVGs/vagas.svg";
import { ReactComponent as Varanda } from "../../Assets/SVGs/varanda.svg";

export const SobreImoveisComponent = (props) => {
  return (
    <SobreImoveis>
      <Flex
        direction="column"
        justifyContent="space-between"
        alignItems="flex-start"
        className="mobile-row"
      >
        <Flex justifyContent="flex-start" className="items">
          {props.condo.attributes?.Faixas?.Metragens ? (
            <Flex className="item" justifyContent="flex-start">
              <Planta style={{ marginRight: "4px" }} />
              {props.condo.attributes?.Faixas?.Metragens.split("-").length > 1
                ? props.condo.attributes?.Faixas?.Metragens.split(
                    "-"
                  )[0].trim() +
                  "m² - " +
                  props.condo.attributes?.Faixas?.Metragens.split(
                    "-"
                  )[1].trim() +
                  "m²"
                : props.condo.attributes?.Faixas?.Metragens.trim() + "m²"}
            </Flex>
          ) : (
            <Flex className="item" justifyContent="flex-start">
              <Planta style={{ marginRight: "4px" }} />
              {props.condo.attributes?.Metragem.trim()}
              m²
            </Flex>
          )}

          {(props.condo.attributes?.Faixas?.Quartos != undefined ||
            props.condo.attributes?.Comodos[0]) && (
            <Flex className="item" justifyContent="flex-start">
              <Cama style={{ marginRight: "10px" }} />
              <p>
                {props.condo.attributes?.Faixas?.Quartos != undefined
                  ? props.condo.attributes?.Faixas?.Quartos
                  : props.condo.attributes?.Comodos[0].Quartos}{" "}
                Quarto(s)
              </p>
            </Flex>
          )}

          {(props.condo.attributes?.Faixas?.Banheiros != undefined ||
            props.condo.attributes?.Comodos[0]) && (
            <Flex className="item" justifyContent="flex-start">
              <Banheiros style={{ marginRight: "10px" }} />
              <p>
                {props.condo.attributes?.Faixas?.Banheiros != undefined
                  ? props.condo.attributes?.Faixas?.Banheiros
                  : props.condo.attributes?.Comodos[0].Banheiros}{" "}
                Banheiro(s)
              </p>
            </Flex>
          )}
        </Flex>
        <Flex justifyContent="flex-start" className="items">
          {(props.condo.attributes?.Condominio_Fechado == true ||
            props.condo.attributes?.Condominio_Fechado == undefined) &&
          props.condo.attributes?.Infraestruturas.filter((infr) => {
            return infr.Infraestrutura === "Loteamento Aberto";
          }).length === 0 ? (
            <Flex className="item" justifyContent="flex-start">
              <Senha style={{ marginRight: "4px" }} />
              {"Condomínio Fechado"}
            </Flex>
          ) : (
            ""
          )}

          {(props.condo.attributes?.Faixas?.Vagas ||
            props.condo.attributes?.Comodos[0]) && (
            <Flex className="item" justifyContent="flex-start">
              <Vagas style={{ marginRight: "10px" }} />
              <p>
                {props.condo.attributes?.Faixas?.Vagas
                  ? props.condo.attributes?.Faixas?.Vagas
                  : props.condo.attributes?.Comodos[0].Vagas}{" "}
                Vaga(s)
              </p>
            </Flex>
          )}
          {}
          {props.condo.attributes?.Infraestruturas.filter(
            (infra) =>
              infra.Infraestrutura != null &&
              infra.Infraestrutura.trim()
                .toLowerCase()
                .includes("varanda gourmet")
          ).length > 0 ? (
            <Flex className="item" justifyContent="flex-start">
              <Varanda style={{ marginRight: "10px" }} />
              {"Varanda Gourmet"}
            </Flex>
          ) : null}
        </Flex>
      </Flex>
    </SobreImoveis>
  );
};
