import axios from "axios";
import React, { useEffect, useState } from "react";
import { ReactComponent as Ap } from "../../Assets/SVGs/ap.svg";
import { ReactComponent as Casa } from "../../Assets/SVGs/casa.svg";
import { ReactComponent as Filtro } from "../../Assets/SVGs/filtro.svg";
import { ReactComponent as Location } from "../../Assets/SVGs/location.svg";
import { ReactComponent as Lote } from "../../Assets/SVGs/lote.svg";
import { ButtonFilter } from "../../Components/Buttons";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import SideBarFilters from "../../Components/SidebarFIlters";
import TalkAboutYourDream from "../../Components/TalkAboutYourDream";
import WhatsYourDream from "../../Components/WhatsYourDream";
import { filters } from "../../Utils/filters";
import { relatePropertyToCondominium } from "../../Utils/imoveisWIthCondo";
import BuildCard from "./BuildCard";
import BuildCardCondo from "./BuildCardCondo";
import {
  Banner,
  Buildings,
  Filters,
  Flex,
  Separetor,
  Space,
  Title,
} from "./styled";

const BuildingVenture = () => {
  const [isOpenFilters, setIsOpenFilters] = useState(false);
  const [city, setCity] = useState("");

  const [isCasa, setIsCasa] = useState(false);
  const [isTerreno, setIsTerreno] = useState(false);
  const [isApartamento, setIsApartamento] = useState(false);
  const [isLancamento, setIsLancamento] = useState(false);
  const [isConstrucao, setIsConstrucao] = useState(false);
  const [isMorar, setIsMorar] = useState(false);
  const [isCondo, setIsCondo] = useState(false);
  const [location, setLocation] = useState([]);
  const [minValue, setMinValue] = useState("");
  const [maxValue, setMaxValue] = useState("");
  const [minMeters, setMinMeters] = useState("");
  const [maxMeters, setMaxMeters] = useState("");
  const [quartosQtd, setQuartosQtd] = useState(0);
  const [banheirosQtd, setBanheirosQtd] = useState(0);
  const [vagasQtd, setVagasQtd] = useState(0);
  const [dif, setDif] = useState([]);
  const [diferenciais, setDiferenciais] = useState([]);

  const [condominios, setCondominios] = useState([]);
  const [condominiosFiltrados, setCondominiosFiltrados] = useState([]);
  const [imoveis, setImoveis] = useState([]);
  const [imoveisFiltrados, setImoveisFiltrados] = useState([]);
  const [isSelect, setIsSelect] = useState("");
  const [isBanner, setIsBanner] = useState("");

  useEffect(() => {
    axios
      .get(
        "https://painel.freedomplanejamento.com.br/api/condominios?populate=*"
      )
      .then((res) => {
        setCondominios(res.data.data);
      });
    axios
      .get(
        "https://painel.freedomplanejamento.com.br/api/imoveis?populate=Endereco&populate=Diferenciais&populate=Fotos&populate=Infraestruturas&populate=Comodos&populate=condominio.Infraestruturas&populate=condominio.Diferenciais&populate=condominio.Fotos"
      )
      .then((res) => setImoveis(res.data.data));
  }, []);

  useEffect(() => {
    setImoveisFiltrados(
      relatePropertyToCondominium(
        imoveis.filter((item) => item.attributes.Mostrar_No_Site === true),
        condominios
      )
    );
    setCondominiosFiltrados(
      condominios.filter(
        (item) =>
          (item.attributes.imoveis.data.length === 0 ||
            item.attributes.imoveis.data.filter(
              (im) => im.attributes.Mostrar_No_Site === true
            ).length === 0) &&
          item.attributes.Mostrar_No_Site === true
      )
    );

    const allImoveisDiferenciais = imoveis.flatMap((item) =>
      item.attributes.Diferenciais.map((d) => d.Diferencial)
    );
    const allCondominiosDiferenciais = condominios.flatMap((item) =>
      item.attributes.Diferenciais.map((d) => d.Diferencial)
    );
    const allDiferenciais = [
      ...allCondominiosDiferenciais,
      ...allImoveisDiferenciais,
    ];

    const uniqueDiferenciais = [...new Set(allDiferenciais)];
    setDiferenciais(uniqueDiferenciais);
  }, [imoveis, condominios]);

  useEffect(() => {
    if (
      isCasa ||
      isTerreno ||
      isApartamento ||
      isLancamento ||
      isConstrucao ||
      isMorar ||
      location
    ) {
      const [imoveisFiltraded, condominiosFiltraded] = filters(
        imoveis.filter((item) => item.attributes.Mostrar_No_Site === true),
        condominios.filter(
          (item) =>
            item.attributes.imoveis.data.length === 0 ||
            item.attributes.imoveis.data.filter(
              (im) => im.attributes.Mostrar_No_Site === true
            ).length === 0
        ),
        {
          city,
          isCasa,
          isTerreno,
          isApartamento,
          isLancamento,
          isConstrucao,
          isMorar,
          minValue,
          maxValue,
          minMeters,
          maxMeters,
          quartosQtd,
          banheirosQtd,
          vagasQtd,
          dif,
          location,
          isCondo,
        }
      );

      setImoveisFiltrados(imoveisFiltraded);
      setCondominiosFiltrados(condominiosFiltraded);
    } else {
      setImoveisFiltrados(imoveis);
      setCondominiosFiltrados(
        condominios.filter(
          (item) =>
            item.attributes.imoveis.data.length === 0 ||
            item.attributes.imoveis.data.filter(
              (im) => im.attributes.Mostrar_No_Site === true
            ).length === 0
        )
      );
    }
  }, [
    isCasa,
    isTerreno,
    isApartamento,
    isLancamento,
    isConstrucao,
    isMorar,
    location,
  ]);

  const handleFilterButton = () => {
    const [imoveisFiltraded, condominiosFiltraded] = filters(
      imoveis,
      condominios.filter((item) => item.attributes.imoveis.data.length === 0),
      {
        isCasa,
        isTerreno,
        isApartamento,
        isLancamento,
        isConstrucao,
        isMorar,
        minValue,
        maxValue,
        minMeters,
        maxMeters,
        quartosQtd,
        banheirosQtd,
        vagasQtd,
        dif,
        location,
        isCondo,
      }
    );
    setImoveisFiltrados(imoveisFiltraded);
    setCondominiosFiltrados(condominiosFiltraded);
  };

  return (
    <div style={{ backgroundColor: "white" }}>
      <SideBarFilters
        handleFilterButton={handleFilterButton}
        isOpen={isOpenFilters}
        setIsOpenFilters={setIsOpenFilters}
        isCasa={isCasa}
        setIsCasa={setIsCasa}
        isTerreno={isTerreno}
        setIsTerreno={setIsTerreno}
        isApartamento={isApartamento}
        setIsApartamento={setIsApartamento}
        isLancamento={isLancamento}
        setIsLancamento={setIsLancamento}
        isMorar={isMorar}
        setIsMorar={setIsMorar}
        isConstrucao={isConstrucao}
        setIsConstrucao={setIsConstrucao}
        isCondo={isCondo}
        setIsCondo={setIsCondo}
        location={location}
        setLocation={setLocation}
        minValue={minValue}
        setMinValue={setMinValue}
        maxValue={maxValue}
        setMaxValue={setMaxValue}
        minMeters={minMeters}
        setMinMeters={setMinMeters}
        maxMeters={maxMeters}
        setMaxMeters={setMaxMeters}
        quartosQtd={quartosQtd}
        setQuartosQtd={setQuartosQtd}
        banheirosQtd={banheirosQtd}
        setBanheirosQtd={setBanheirosQtd}
        vagasQtd={vagasQtd}
        setVagasQtd={setVagasQtd}
        dif={dif}
        setDif={setDif}
        diferenciais={diferenciais}
      />
      <Header />
      <Banner backgroundNow={isBanner}>
        <div className="__wrap">
          <Title>
            Encontre o{" "}
            <span>
              imóvel
              <br />
              <br />
              ideal
            </span>{" "}
            pra você
          </Title>
        </div>
      </Banner>
      <Filters className="__wrap">
        <form>
          <Flex justifyContent={"flex-start"} direction={"row"}>
            <Flex className="desktop_filter" justifyContent={"space-between"}>
              <Flex
                onClick={() => {
                  setIsApartamento(!isApartamento);
                  handleFilterButton();
                }}
                className={`item ${isApartamento ? "__actived" : ""}`}
              >
                <Ap />
                <p>Apartamento</p>
              </Flex>
              <Space />
              <Flex
                onClick={() => {
                  setIsCasa(!isCasa);
                  handleFilterButton();
                }}
                className={`item ${isCasa ? "__actived" : ""}`}
              >
                <Casa />
                <p>Casa</p>
              </Flex>
              <Space />
              <Flex
                onClick={() => {
                  setIsTerreno(!isTerreno);
                }}
                className={`item ${isTerreno ? "__actived" : ""}`}
              >
                <Lote />
                <p>Terreno</p>
              </Flex>
            </Flex>
            <Separetor />
            <Flex className="mobile_filter">
              <label className={isLancamento ? "__actived" : ""}>
                <Flex justifyContent={"center"} direction={"row"}>
                  <input
                    type="checkbox"
                    id="lancamento"
                    name="lancamento"
                    value="Lançamentos"
                    checked={isLancamento}
                    onChange={() => setIsLancamento(!isLancamento)}
                  />
                  <p>Lançamentos</p>
                </Flex>
              </label>
              <Space />
              <label className={isMorar ? "__actived" : ""}>
                <Flex justifyContent={"center"} direction={"row"}>
                  <input
                    type="checkbox"
                    id="morar"
                    name="morar"
                    value="Pronto para morar"
                    checked={isMorar}
                    onChange={() => setIsMorar(!isMorar)}
                  />
                  <p>Pronto para morar</p>
                </Flex>
              </label>
              <Space />
              <label className={isConstrucao ? "__actived" : ""}>
                <Flex justifyContent={"center"} direction={"row"}>
                  <input
                    type="checkbox"
                    id="construcao"
                    name="construcao"
                    value="Em construção"
                    checked={isConstrucao}
                    onChange={() => setIsConstrucao(!isConstrucao)}
                  />
                  <p>Em construção</p>
                </Flex>
              </label>
            </Flex>
            <Separetor />
            <label
              className={isSelect != "" ? "__actived-select" : ""}
              htmlFor="cities"
            >
              <Flex justifyContent={"flex-start"} direction={"row"}>
                <Location />
                <select
                  id="cities"
                  onChange={(ev) => {
                    if (ev.target.value != "") {
                      setLocation([
                        {
                          value: ev.target.value,
                          label: ev.target.value,
                        },
                      ]);
                    } else {
                      setLocation([]);
                    }
                  }}
                >
                  <option value="">Filtrar por cidade</option>
                  <option value="Itu">Itu</option>
                  <option value="Salto">Salto</option>
                  <option value="Indaiatuba">Indaiatuba</option>
                </select>
              </Flex>
            </label>
            <Space />
            <ButtonFilter onClick={() => setIsOpenFilters(!isOpenFilters)}>
              <Filtro />
              Mais Filtros
            </ButtonFilter>
          </Flex>
        </form>
      </Filters>
      <Buildings key="12321dsa123asd">
        <div className="__wrap" key={"asdasdasdasdasd"}>
          {condominiosFiltrados.map((condominio) => (
            <>
              <BuildCardCondo key={condominio.id} condominio={condominio} />
            </>
          ))}
          {imoveisFiltrados?.map((imovel) => (
            <>
              <BuildCard key={imovel.id} imovel={imovel} />
            </>
          ))}
        </div>
      </Buildings>
      <TalkAboutYourDream />
      <WhatsYourDream />
      <Footer />
    </div>
  );
};

export default BuildingVenture;
