export const relatePropertyToCondominium = (imovs, condos) => {
  let returnedImovs = imovs;

  returnedImovs.forEach((imov, index) => {
    imovs[index].attributes.condominio = condos.filter(
      (condo) => imov?.attributes?.condominio?.data?.id == condo.id
    )[0];
  });

  return returnedImovs;
};
