import instagram from "../../Assets/Images/instagram.png";
import { Title, BackgroundStyle, Text } from "./styled";
import Button from "../../Components/Buttons";

const Insta = () => {
  return (
    <BackgroundStyle>
      <div className="__wrap">
        <img src={instagram} />
        <Text>
          <Title>
            Conheça a Freedom e os <br />
            profissionais que tornam <br />
            <span>seus sonhos possíveis!</span>
          </Title>
          <Button>
            <a
              href="https://www.instagram.com/freedom.imobiliaria/"
              target={"_blank"}
            >
              <p>@freedom.imobiliaria</p>
            </a>
          </Button>
        </Text>
      </div>
    </BackgroundStyle>
  );
};

export default Insta;
