import { Flex, Separetor, Videos } from "./styled";

export const VideoComponent = (props) => {
  const getURL = () => {
    if (props.condo.attributes?.Video != null) {
      if (
        props.condo.attributes?.Video.split("=")[1].split("&t")[1] != undefined
      ) {
        return props.condo.attributes?.Video.split("=")[1].split("&t")[0];
      } else {
        return props.condo.attributes?.Video.split("=")[1];
      }
    }
  };

  return props.condo.attributes?.Video != null ? (
    <Videos>
      <h3>Vídeo</h3>
      <Separetor />
      <Flex>
        <iframe
          src={"https://www.youtube.com/embed/" + getURL()}
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </Flex>
    </Videos>
  ) : (
    ""
  );
};
