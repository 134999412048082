import { HighlightsStyled, Item, Flex, TextBox, Text, Title } from "./styled";
// import { Carousel } from "@trendyol-js/react-carousel";
import { Link } from "react-router-dom";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import img1 from "../../Assets/Images/imgsho1.png";
import img2 from "../../Assets/Images/imgsho2.png";
import img3 from "../../Assets/Images/imgsho3.png";
import imgcasa3 from "../../Assets/Images/imgcasa3.png";
import imgcasa2 from "../../Assets/Images/imgcasa2.png";
import imgcasa1 from "../../Assets/Images/imgcasa1.png";
import lote1 from "../../Assets/Images/lote1.png";
import lote2 from "../../Assets/Images/lote2.png";
import lote3 from "../../Assets/Images/lote3.png";
import Button from "../Buttons";

const Highlights = () => {
  return (
    <HighlightsStyled>
      <div className="__wrap">
        <Carousel
          autoPlay={true}
          showStatus={false}
          swipeable={true}
          emulateTouch={true}
          infiniteLoop={true}
          showArrows={false}
        >
          <Item>
            <Flex direction={window.screen.width > 480 ? "column" : "column"}>
              <Flex
                direction={window.screen.width > 480 ? "row" : "column"}
                style={{ marginBottom: "20px" }}
                className="mobile-box1"
              >
                <TextBox>
                  Mais lazer, segurança <br />e tranquilidade
                </TextBox>
                <img
                  style={{
                    display: `${
                      window.screen.width > 480 ? "inline-block" : "none"
                    }`,
                  }}
                  src={img1}
                  width={"196px"}
                  height={"auto"}
                />
              </Flex>
              <Flex style={{ width: "100%" }} justifyContent={"space-between"}>
                <img
                  src={img2}
                  style={{
                    display: `${
                      window.screen.width > 480 ? "inline-block" : "none"
                    }`,
                  }}
                />
                <img src={img3} />
              </Flex>
            </Flex>
            <Text>
              <Title>
                Conheça os principais
                <br /> lançamentos de{" "}
                <span>
                  prédios
                  <br /> residenciais
                </span>{" "}
                da região
              </Title>
              <Button>
                <Link to="/empreendimentos?tipo=apartamento">Saiba Mais</Link>
              </Button>
            </Text>
          </Item>
          <Item>
            <Flex direction={"column"}>
              <Flex className="mobile-box1" style={{ marginBottom: "20px" }}>
                <TextBox>
                  Espaço, comodidade
                  <br /> e versatilidade
                </TextBox>
                <img
                  src={imgcasa1}
                  style={{
                    display: `${
                      window.screen.width > 480 ? "inline-block" : "none"
                    }`,
                  }}
                />
              </Flex>
              <Flex style={{ width: "100%" }} justifyContent={"space-between"}>
                <img
                  src={imgcasa3}
                  style={{
                    display: `${
                      window.screen.width > 480 ? "inline-block" : "none"
                    }`,
                  }}
                />
                <img src={imgcasa2} />
              </Flex>
            </Flex>
            <Text>
              <Title>
                Garagem? Churrasqueira?
                <br />
                Escolha a <span>casa ideal</span> para
                <br /> você e sua família
              </Title>
              <Button>
                <Link to="/empreendimentos?tipo=casa">Saiba Mais</Link>
              </Button>
            </Text>
          </Item>
          <Item>
            <Flex direction={"column"}>
              <Flex className="mobile-box1" style={{ marginBottom: "20px" }}>
                <TextBox>
                  Construa <br />
                  como quiser
                </TextBox>
                <img
                  src={lote1}
                  style={{
                    display: `${
                      window.screen.width > 480 ? "inline-block" : "none"
                    }`,
                  }}
                />
              </Flex>
              <Flex style={{ width: "100%" }} justifyContent={"space-between"}>
                <img
                  src={lote2}
                  style={{
                    display: `${
                      window.screen.width > 480 ? "inline-block" : "none"
                    }`,
                  }}
                />
                <img src={lote3} />
              </Flex>
            </Flex>
            <Text>
              <Title>
                O <span>terreno</span> é a escolha perfeita para você construir{" "}
                <br />a casa dos seus sonhos!
              </Title>
              <Button>
                <Link to="/empreendimentos?tipo=terreno">Saiba Mais</Link>
              </Button>
            </Text>
          </Item>
        </Carousel>
      </div>
    </HighlightsStyled>
  );
};

export default Highlights;
