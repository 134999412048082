import React, { useEffect } from "react";
import aspas from "../../Assets/Images/aspas.png";
import casaFreedom from "../../Assets/Images/casafreedom.png";
import mesa1 from "../../Assets/Images/mesa.png";
import mesa2 from "../../Assets/Images/mesa2.png";
import objt from "../../Assets/Images/objectivo.png";
import parede from "../../Assets/Images/parede.png";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import Partners from "../../Components/Partners";
import TalkAboutYourDream from "../../Components/TalkAboutYourDream";
import Team from "../../Components/Team";
import WhatsYourDream from "../../Components/WhatsYourDream";
import {
  AboutStyled, Banner, Description, Flex, Message, OurObjective, OurStructure, SubTitle, Text, Title, TitleT
} from "./styled";

const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Header />
      <Banner>
        <div className="__wrap">
          <Flex>
            <Title>
              <Flex>
                <Title>
                  Somos a <span>Freedom</span>
                </Title>
                <h2>
                  Estamos aqui pra tornar o seu sonho, uma realidade! E por isso
                  nos apelidamos carinhosamente como:
                  <br />
                  <span>#fabrica de sonhos</span>
                </h2>
              </Flex>
            </Title>
          </Flex>
        </div>
      </Banner>
      <AboutStyled>
        <div className="__wrap">
          <Flex direction={"row"}>
            <Text>
              <SubTitle>
                A <span>Freedom</span>
              </SubTitle>
              <Description>
                A Freedom nasceu em 2019 a partir da nossa vontade de
                proporcionar liberdade para todos aqueles que buscam conquistar
                o tão sonhado lar doce lar! Nesse caminho, atraímos pessoas com
                a mesma vontade e nos tornamos um time.
                <br />
                <br />
                Com muita honestidade e responsabilidade, trabalhamos todos os
                dias para que seu sonho se torne real! Seja ele apartamento,
                casa ou terreno, vamos realizar juntos?
              </Description>
            </Text>
            <img src={casaFreedom} />
          </Flex>
        </div>
      </AboutStyled>
      <OurStructure>
        <div className="__wrap">
          <Flex
            alignItems={"stretch"}
            justifyContent={"center"}
            direction={"row"}
          >
            <Flex className="txts">
              <Message>
                <img src={aspas} /> Nossas paredes estampam tudo o que
                <br />
                {" "}acreditamos, e os motivos pelos quais
                <br /> trabalhamos diariamente!
              </Message>
              <Flex direction={"row"}>
                <img
                  className={"img1"}
                  src={mesa1}
                  width={"350px"}
                  height={"350px"}
                />
                <img src={mesa2} width={"350px"} height={"350px"} />
              </Flex>
            </Flex>
            <img className="parede" src={parede} />
          </Flex>
        </div>
      </OurStructure>
      <OurObjective>
        <div className="__wrap">
          <Flex direction={"row"}>
            <Text>
              <TitleT>
                Trabalhamos para que você
                <br />
                <span> seja livre para realizar!</span>
              </TitleT>
              <p>
                Hoje, vivemos em um mundo onde o ser humano não se sente parte
                fundamental <b>na realização de um dos grandes sonhos</b> da
                humanidade: A conquista de um imóvel!
                <br />
                <br />
                Seja ele o <b>primeiro imóvel</b> ou um <b>investimento</b>,
                seja <b>casa</b>, apartamento ou terreno, seja grande ou
                pequeno, nós defendemos que você merece viver uma experiência
                inesquecível a cada imóvel conquistado e vibrar com{" "}
                <b>a alegria a cada etapa desse processo.</b>
                <br />
                <br />
                Nós acreditamos que cada indivíduo é diferente em suas
                características, sonhos, vitórias e aprendizados e que todo
                mundo tem uma história de vida incrível a compartilhar.
              </p>
              <br />
              <TitleT>
                Queremos fazer parte de um capítulo da{" "}
                <span>sua história!</span>
              </TitleT>
              <p>
                Para que isso aconteça, buscaremos inspiração nas histórias
                reais de nossos clientes, conectando nossos objetivos e
                propósitos e transformando a vida das pessoas de alguma maneira.
              </p>
              <br />
              <TitleT>
                <span>Seja livre</span> para realizar!
              </TitleT>
            </Text>
            <img src={objt} />
          </Flex>
        </div>
      </OurObjective>
      <Team />
      <Partners />
      <TalkAboutYourDream />
      <WhatsYourDream />
      <Footer />
    </>
  );
};

export default About;
