import { motion } from "framer-motion";
import { useCallback, useEffect, useRef, useState } from "react";
import { ReactComponent as NoImage } from "../../Assets/SVGs/no-image2.svg";
import { Flex, ImagesShow, LeftArrow, RightArrow } from "./styled";

const ImagesShowComponent = (props) => {
  const carousel = useRef();
  const [qtdItems, setQtdItems] = useState([]);
  const [slide, setSlide] = useState(0);
  const [width, setWidth] = useState(0);
  const [zoom, setZoom] = useState(false);
  const [zoomMain, setZoomMain] = useState("");

  const escFunction = useCallback((e) => {
    if (e.keyCode == 27) {
      setZoom(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);

    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, []);

  useEffect(() => {
    props.condo.attributes?.Fotos.data?.length +
      (props.condo.attributes?.condominio?.data
        ? props.condo.attributes?.condominio?.data.attributes?.Fotos.data
            ?.length
        : 0) <
    3
      ? props.condo.attributes?.Fotos.data?.length +
          (props.condo.attributes?.condominio?.data
            ? props.condo.attributes?.condominio?.data.attributes?.Fotos.data
                ?.length
            : 0) <
        2
        ? props.condo.attributes?.Fotos.data?.length +
            (props.condo.attributes?.condominio?.data
              ? props.condo.attributes?.condominio?.data.attributes?.Fotos.data
                  ?.length
              : 0) ==
          0
          ? setQtdItems(
              props.condo.attributes?.Fotos.data?.length +
                (props.condo.attributes?.condominio?.data
                  ? props.condo.attributes?.condominio?.data.attributes?.Fotos
                      .data?.length
                  : 0) +
                3
            )
          : setQtdItems(
              props.condo.attributes?.Fotos.data?.length +
                (props.condo.attributes?.condominio?.data
                  ? props.condo.attributes?.condominio?.data.attributes?.Fotos
                      .data?.length
                  : 0) +
                2
            )
        : setQtdItems(
            props.condo.attributes?.Fotos.data?.length +
              (props.condo.attributes?.condominio?.data
                ? props.condo.attributes?.condominio?.data.attributes?.Fotos
                    .data?.length
                : 0) +
              1
          )
      : setQtdItems(
          props.condo.attributes?.Fotos.data?.length +
            (props.condo.attributes?.condominio?.data
              ? props.condo.attributes?.condominio?.data.attributes?.Fotos.data
                  ?.length
              : 0) +
            3
        );
    // setQtdItems(props.condo.attributes?.Fotos.data?.length);
    setWidth(
      carousel.current?.clientWidth -
        (props.condo.attributes?.Fotos.data?.length +
          (props.condo.attributes?.condominio?.data
            ? props.condo.attributes?.condominio?.data.attributes?.Fotos.data
                ?.length
            : 0)) *
          640
    );
  }, [props.condo]);

  return (
    <>
      <ImagesShow>
        <div
          tabIndex="0"
          onClick={() => setZoom(false)}
          className={zoom ? "ZoomImgs _actived" : "ZoomImgs"}
        >
          <img src={zoomMain} />
        </div>
        <motion.div
          ref={carousel}
          className="Carousel"
          whileTap={{ cursor: "grabbing" }}
        >
          <LeftArrow
            onClick={() => {
              if (slide + 640 <= 0) {
                setSlide(slide + 640);
              }
            }}
          />
          <RightArrow
            onClick={() => {
              if (slide - 640 >= width) {
                setSlide(slide - 640);
              }
            }}
          />
          <motion.div
            className="inner"
            style={{
              width:
                (qtdItems < 3
                  ? qtdItems < 2
                    ? qtdItems == 0
                      ? qtdItems + 3
                      : qtdItems + 2
                    : qtdItems + 1
                  : qtdItems) *
                  640 +
                "px",
            }}
            animate={{ x: slide + "px" }}
            drag={"x"}
            dragConstraints={{ right: 0, left: width }}
          >
            {props.condo.attributes?.Fotos.data?.map((item) => (
              <motion.div
                key={item.attributes.url}
                className={"item"}
                onClick={() => {
                  setZoomMain(
                    "https://painel.freedomplanejamento.com.br" +
                      item.attributes.url
                  );
                  setZoom(true);
                }}
                style={{
                  backgroundImage: `url(https://painel.freedomplanejamento.com.br${item.attributes.url})`,
                }}
              ></motion.div>
            ))}
            {props.condo.attributes?.condominio?.data &&
              props.condo.attributes?.condominio?.data.attributes?.Fotos.data?.map(
                (item) => (
                  <motion.div
                    key={item.attributes.url}
                    className={"item"}
                    onClick={() => {
                      setZoomMain(
                        "https://painel.freedomplanejamento.com.br" +
                          item.attributes.url
                      );
                      setZoom(true);
                    }}
                    style={{
                      backgroundImage: `url(https://painel.freedomplanejamento.com.br${item.attributes.url})`,
                    }}
                  ></motion.div>
                )
              )}
            {props.condo.attributes?.Fotos.data?.length +
              (props.condo.attributes?.condominio?.data
                ? props.condo.attributes?.condominio?.data.attributes?.Fotos
                    .data?.length
                : 0) ==
            0 ? (
              <>
                <motion.div
                  key={2}
                  className={"item"}
                  style={{ backgroundColor: `#f1f1f1` }}
                >
                  <Flex direction="column" style={{ height: "100%" }}>
                    <NoImage
                      style={{ width: "50px", fill: "rgb(165, 165, 168)" }}
                    />
                    <p style={{ color: "rgb(165, 165, 168)" }}>SEM FOTO</p>
                  </Flex>
                </motion.div>
                <motion.div
                  key={2}
                  className={"item"}
                  style={{ backgroundColor: `#f1f1f1` }}
                >
                  <Flex direction="column" style={{ height: "100%" }}>
                    <NoImage
                      style={{ width: "50px", fill: "rgb(165, 165, 168)" }}
                    />
                    <p style={{ color: "rgb(165, 165, 168)" }}>SEM FOTO</p>
                  </Flex>
                </motion.div>
                <motion.div
                  key={2}
                  className={"item"}
                  style={{ backgroundColor: `#f1f1f1` }}
                >
                  <Flex direction="column" style={{ height: "100%" }}>
                    <NoImage
                      style={{ width: "50px", fill: "rgb(165, 165, 168)" }}
                    />
                    <p style={{ color: "rgb(165, 165, 168)" }}>SEM FOTO</p>
                  </Flex>
                </motion.div>
              </>
            ) : (
              ""
            )}
            {props.condo.attributes?.Fotos.data?.length < 3 &&
            props.condo.attributes?.Fotos.data?.length != 0 ? (
              props.condo.attributes?.Fotos.data?.length < 2 ? (
                <>
                  <motion.div
                    key={2}
                    className={"item"}
                    style={{ backgroundColor: `#f1f1f1` }}
                  >
                    <Flex direction="column" style={{ height: "100%" }}>
                      <NoImage
                        style={{ width: "50px", fill: "rgb(165, 165, 168)" }}
                      />
                      <p style={{ color: "rgb(165, 165, 168)" }}>SEM FOTO</p>
                    </Flex>
                  </motion.div>
                  <motion.div
                    key={2}
                    className={"item"}
                    style={{ backgroundColor: `#f1f1f1` }}
                  >
                    <Flex direction="column" style={{ height: "100%" }}>
                      <NoImage
                        style={{ width: "50px", fill: "rgb(165, 165, 168)" }}
                      />
                      <p style={{ color: "rgb(165, 165, 168)" }}>SEM FOTO</p>
                    </Flex>
                  </motion.div>
                </>
              ) : (
                <motion.div
                  key={2}
                  className={"item"}
                  style={{ backgroundColor: `#f1f1f1` }}
                >
                  <Flex direction="column" style={{ height: "100%" }}>
                    <NoImage
                      style={{ width: "50px", fill: "rgb(165, 165, 168)" }}
                    />
                    <p style={{ color: "rgb(165, 165, 168)" }}>SEM FOTO</p>
                  </Flex>
                </motion.div>
              )
            ) : (
              ""
            )}
          </motion.div>
        </motion.div>
      </ImagesShow>
    </>
  );
};

export default ImagesShowComponent;
