import styled from "styled-components";

const SideBarFiltersStyle = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  background-color: white;
  height: 100vh;
  width: 40%;
  z-index: 1001;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.2);
  overflow: auto;
  transform: translateX(100%);
  transition: transform 0.4s ease;

  @media only screen and (max-width: 430px) {
    width: 100%;
  }

  &.open {
    transform: translateX(0);
  }

  > .title {
    padding: 24px;
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: 22px;
    color: #575656;
    justify-content: space-between;
    border-bottom: 2px solid #e7e7e7;
    margin-bottom: 20px;

    svg {
      cursor: pointer;
    }
  }

  .type-one {
    padding: 0 24px;

    .divider {
      border-bottom: 2px solid #e7e7e7;
      width: 100%;
      margin-top: 25px;
    }

    .title {
      font-size: 18px;
      font-weight: bold;
      color: #575656;
      margin-bottom: 16px;
    }

    .inputs {
      display: flex;
      justify-content: space-between;
      align-items: center;

      @media only screen and (max-width: 430px) {
        gap: 2px
      }

      div {
        border: 2px solid #e7e7e7;
        display: flex;
        background-color: white;
        padding: 10px;
        align-items: center;
        border-radius: 6px;
        justify-content: center;
        width: 30%;
        gap: 10px;
        cursor: pointer;
        margin-bottom: 10px;

        @media only screen and (max-width: 430px) {
          font-size: 14px;

          svg {
            display: none;
          }
        }

        &:hover,
        &.__actived {
          background-color: #d25148;
          color: white;

          svg {
            path,
            line {
              stroke: white !important;
            }
          }
        }
      }
    }

    .is-condo {
      display: flex;
      align-items: center;
      border: 2px solid #e7e7e7;
      padding: 10px;
      border-radius: 6px;
      cursor: pointer;

      svg {
        margin-right: 10px;
      }

      .switch {
        margin-left: auto;
        position: relative;
        display: inline-block;
        width: 28px;
        height: 16px;
      }

      /* Hide default HTML checkbox */
      .switch input {
        opacity: 0;
        width: 0;
        height: 0;
      }

      /* The slider */
      .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #575656;
        -webkit-transition: 0.4s;
        transition: 0.4s;
      }

      .slider:before {
        position: absolute;
        content: "";
        height: 12px;
        width: 12px;
        left: 2px;
        bottom: 2px;
        background-color: white;
        -webkit-transition: 0.4s;
        transition: 0.4s;
      }

      input:checked + .slider {
        background-color: #d25148;
      }

      input:focus + .slider {
        box-shadow: 0 0 1px #d25148;
      }

      input:checked + .slider:before {
        -webkit-transform: translateX(12px);
        -ms-transform: translateX(12px);
        transform: translateX(12px);
      }

      /* Rounded sliders */
      .slider.round {
        border-radius: 34px;
      }

      .slider.round:before {
        border-radius: 50%;
      }
    }
  }

  .type-two {
    margin-top 25px;
    padding: 0 24px;

    .divider {
      border-bottom: 2px solid #e7e7e7;
      width: 100%;
      margin-top: 25px;
    }

    .title {
      font-size: 18px;
      font-weight: bold;
      color: #575656;
      margin-bottom: 16px;
    }
  }

  .type-three {
    margin-top 25px;
    padding: 0 24px;

    .divider {
      border-bottom: 2px solid #e7e7e7;
      width: 100%;
      margin-top: 25px;
    }

    .title {
      font-size: 18px;
      font-weight: bold;
      color: #575656;
      margin-bottom: 16px;
    }

    .inputs {
      display: flex;
      justify-content: space-between;
      align-items: center;

      @media only screen and (max-width: 430px) {
        flex-direction: column;
      }

      div {
        border: 2px solid #e7e7e7;
        display: flex;
        background-color: white;
        padding: 8px;
        align-items: center;
        border-radius: 6px;
        justify-content: center;
        width: 32%;
        cursor: pointer;
        margin-bottom: 10px;
        @media only screen and (max-width: 430px) {
          width: 100%;
        }

        &:hover,
        &.__actived {
          background-color: #d25148;
          color: white;

          svg {
            path,
            line {
              stroke: white !important;
            }
          }
        }
      }
    }
  }

  .type-four {
    margin-top 25px;
    padding: 0 24px;

    .divider {
      border-bottom: 2px solid #e7e7e7;
      width: 100%;
      margin-top: 25px;
    }

    .title {
      font-size: 18px;
      font-weight: bold;
      color: #575656;
      margin-bottom: 16px;
    }

    .inputs {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;

      label {
        width: 48%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        
        input {
          border-bottom: 2px solid #e7e7e7;
          width: 70%;
          margin-left: 5px;
          color: #181818;
          text-align: right;
        }

        p {
          color: #C4C4C4;
          width: auto;
          font-size: 22px;
          font-weight: bold;
        }
      }
    }
  }

  .type-five {
    margin-top 25px;
    padding: 0 24px;

    .divider {
      border-bottom: 2px solid #e7e7e7;
      width: 100%;
      margin-top: 25px;
    }

    .title {
      font-size: 18px;
      font-weight: bold;
      color: #575656;
      margin-bottom: 16px;
    }

    .inputs {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;

      label {
        width: 48%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        
        input {
          border-bottom: 2px solid #e7e7e7;
          width: 100%;
          margin-left: 5px;
          color: #181818;
          text-align: right;
        }

        p {
          color: #C4C4C4;
          font-size: 22px;
          font-weight: bold;
        }
      }
    }
  }

  .type-six {
    margin-top 25px;
    padding: 0 24px;

    .divider {
      border-bottom: 2px solid #e7e7e7;
      width: 100%;
      margin-top: 25px;
    }

    .title {
      font-size: 18px;
      font-weight: bold;
      color: #575656;
      margin-bottom: 16px;
    }

    .inputs {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 8px;

      div {
        border: 2px solid #e7e7e7;
        display: flex;
        background-color: white;
        padding: 10px;
        align-items: center;
        border-radius: 6px;
        justify-content: center;
        width: 30%;
        gap: 10px;
        cursor: pointer;
        margin-bottom: 10px;

        &:hover,
        &.__actived {
          background-color: #d25148;
          color: white;

          svg {
            path,
            line {
              stroke: white !important;
            }
          }
        }
      }
    }
  }

  .type-seven {
    margin-top 25px;
    padding: 0 24px;

    .divider {
      border-bottom: 2px solid #e7e7e7;
      width: 100%;
      margin-top: 25px;
    }

    .title {
      font-size: 18px;
      font-weight: bold;
      color: #575656;
      margin-bottom: 16px;
    }

    .inputs {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 8px;

      div {
        border: 2px solid #e7e7e7;
        display: flex;
        background-color: white;
        padding: 10px;
        align-items: center;
        border-radius: 6px;
        justify-content: center;
        width: 30%;
        gap: 10px;
        cursor: pointer;
        margin-bottom: 10px;

        &:hover,
        &.__actived {
          background-color: #d25148;
          color: white;

          svg {
            path,
            line {
              stroke: white !important;
            }
          }
        }
      }
    }
  }

  .type-eight {
    margin-top 25px;
    padding: 0 24px;

    .divider {
      border-bottom: 2px solid #e7e7e7;
      width: 100%;
      margin-top: 25px;
    }

    .title {
      font-size: 18px;
      font-weight: bold;
      color: #575656;
      margin-bottom: 16px;
    }

    .inputs {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 8px;

      div {
        border: 2px solid #e7e7e7;
        display: flex;
        background-color: white;
        padding: 10px;
        align-items: center;
        border-radius: 6px;
        justify-content: center;
        width: 30%;
        gap: 10px;
        cursor: pointer;
        margin-bottom: 10px;

        &:hover,
        &.__actived {
          background-color: #d25148;
          color: white;

          svg {
            path,
            line {
              stroke: white !important;
            }
          }
        }
      }
    }
  }

  .type-nine {
    margin-top 25px;
    padding: 0 24px;

    .divider {
      border-bottom: 2px solid #e7e7e7;
      width: 100%;
      margin-top: 25px;
    }

    .title {
      font-size: 18px;
      font-weight: bold;
      color: #575656;
      margin-bottom: 16px;
    }

    .inputs {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;
      gap: 8px;

      div {
        border: 2px solid #e7e7e7;
        display: flex;
        background-color: white;
        padding: 10px;
        align-items: center;
        border-radius: 6px;
        justify-content: center;
        width: auto;
        min-width: 20%;
        gap: 10px;
        cursor: pointer;
        margin-bottom: 10px;

        input {
          display: block;
          width: 20px;
          height: 20px;
          appearance: auto;
        }

        &:hover,
        &.__actived {
          background-color: #d25148;
          color: white;

          svg {
            path,
            line {
              stroke: white !important;
            }
          }
        }
      }
    }
  }

  .final {
    padding: 0 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    div {
      width: 48%;
      margin-top: 20px;
      text-align: center;
      border: 2px solid #e7e7e7;
      background-color: white;
      padding: 10px;
      align-items: center;
      border-radius: 6px;
      font-size: 18px;
      cursor: pointer;
      color: #575656;
      font-weight: bold;
      margin-bottom: 10px;
      
      &.filterbutton {
        color: white;
        background-color: #d25148;

        &:hover{
          background-color: white;
          color: #d25148;
        }
      }

      &.clear {
        &:hover {
          background-color: #d25148;
          color: white;
        }
      }
    }
  }
`;

export { SideBarFiltersStyle };
