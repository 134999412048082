import styled from "styled-components";

const HighlightsStyled = styled.div`
  background-color: rgb(211, 216, 219);
  margin: 50px 0;
  @media only screen and (max-width: 430px) {
    .carousel.carousel-slider {
      overflow: inherit !important;
    }
    .control-dots {
      bottom: -60px;
    }
  }
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: 430px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0 20px;

    .mobile-box1 {
      width: 100%;
      margin: 0;
      font-size: 23px;

      div {
        width: 100%;
        margin: 0;
      }

      img {
        display: none !important;
      }
    }
  }
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
  text-align: left !important;

  ${(props) => {
    let propsStyles = props.direction
      ? `flex-direction: ${props.direction};`
      : "flex-direction: row;";

    propsStyles += props.justifyContent
      ? `justify-content: ${props.justifyContent};`
      : "justify-content: center;";
    return propsStyles;
  }}

  img {
    width: auto !important;
  }
`;

const TextBox = styled.div`
  background-color: #181818;
  color: white;
  margin-right: 20px;
  font-size: 26px;
  font-weight: 500;
  border-radius: 15px;
  height: 175px;
  width: 440px;
  padding: 25px 50px 45px 50px;
`;

const Text = styled.div`
  width: 45%;
  margin-left: 50px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  @media only screen and (max-width: 430px) {
    width: 100%;
    margin-left: 0px;
  }
`;

const Title = styled.p`
  color: #575656;
  font-size: 34px;
  font-weight: 600;
  text-align: left !important;

  @media only screen and (max-width: 430px) {
    font-size: 26px;
  }

  span {
    color: #d25148;
  }
`;

export { Flex, HighlightsStyled, Item, Text, TextBox, Title };
