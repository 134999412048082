import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from "react-router-dom";
import About from "../Pages/About";
import BuildingVenture from "../Pages/BuildingVenture";
import Contact from "../Pages/Contact";
import ContentBuildingVenture from "../Pages/ContentBuildingVenture";
import Home from "../Pages/Home";
import Privacy from "../Pages/Privacy";
import Thanks from "../Pages/Thanks";

const routers = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/" element={<Home />} />
      <Route path="/politica-de-privacidade" element={<Privacy />} />
      <Route path="/contato/obrigado" element={<Thanks />} />
      <Route path="/contato" element={<Contact />} />
      <Route path="/sobre" element={<About />} />
      <Route path="/empreendimentos" element={<BuildingVenture />} />
      <Route
        path="/empreendimentos/:condominio"
        element={<ContentBuildingVenture />}
      />
    </>
  )
);

export default routers;
