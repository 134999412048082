import styled from "styled-components";
import banner from "../../Assets/Images/tks-bg.png";

const Banner = styled.div`
  height: 90vh;
  background-image: url(${banner});
  background-size: cover;
  background-position: center;
`;

const Title = styled.h1`
  color: white;
  font-weight: 500;
  font-size: 40px;
  line-height: 40px;
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
  color: #d25148;

  span {
    color: white;
    font-size: 22px;
    margin-top: 20px;
    display: inline-block;
  }
`;

const Flex = styled.div`
  display: flex;
  height: 100%;

  .txts {
    margin-right: 50px;
    div .img1 {
      margin-right: 20px;
    }
  }

  .parede {
    height: 617px;
  }

  ${(props) => {
    let propsStyles = props.direction
      ? `flex-direction: ${props.direction};`
      : "flex-direction: column;";

    propsStyles += props.alignItems
      ? `align-items: ${props.alignItems};`
      : "align-items: center;";

    propsStyles += props.justifyContent
      ? `justify-content: ${props.justifyContent};`
      : "justify-content: center;";

    return propsStyles;
  }}
`;

export { Banner, Title, Flex };
