import styled from "styled-components";
import ave from "../../Assets/Images/vetor-bg.png";

const Title = styled.h1`
  color: white;
  font-weight: 500;
  font-size: 36px;
  line-height: 40px;
  width: 100%;
  margin-bottom: 20px;
  text-align: center;

  @media only screen and (max-width: 430px) {
    font-size: 30px;
  }

  span {
    color: #d25148;
  }
`;

const BackgroundStyle = styled.div`
  padding: 100px 0;
  background: black url(${ave}) no-repeat 90% center;

  @media only screen and (max-width: 430px) {
    padding: 50px 0;
  }

  .__wrap {
    display: flex;
    align-items: center;
    justify-content: center;

    button:nth-child(2) {
      margin: 0 20px;
    }
  }
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  ${(props) => {
    let propsStyles = props.direction
      ? `flex-direction: ${props.direction};`
      : "flex-direction: column;";
    return propsStyles;
  }}

  @media only screen and (max-width: 430px) {
    flex-direction: column;

    button {
      margin-bottom: 20px !important;
    }
  }
`;

export { BackgroundStyle, Flex, Title };
