import styled from "styled-components";

const TeamStyle = styled.div`
  background-color: #181818;
  padding: 20px 0;

  @media only screen and (max-width: 430px) {
    padding: 20px;
  }
`;

const Title = styled.p`
  font-size: 32px;
  font-weight: 500;
  margin: 40px 0;
  color: white;
  span {
    color: #d25148;
  }
`;

const Items = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
`;

const Item = styled.div`
  background-color: black;
  width: 360px;
  height: 360px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 20px;
  position: relative;
  border-radius: 16px;
  margin-bottom: 30px;

  @media only screen and (max-width: 430px) {
    width: 100%;
  }

  &:nth-child(3n - 1) {
    margin-left: auto;
    margin-right: auto;

    &:last-child {
      margin-left: 60px;
    }
  }

  &:hover {
    .name,
    .cargo,
    .description {
      opacity: 1;
    }
    &:after {
      opacity: 0.8;
    }
  }

  &:after {
    content: "";
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    background-color: black;
    border-radius: 16px;
    top: 0;
    left: 0;
    z-index: 0;
    opacity: 0;
    transition: opacity 0.4s ease;
  }

  &:before {
    content: "";
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    // background-color: red;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    ${(props) => {
      return `background-image: url("${props.bg}");`;
    }}
    filter: blur(1px);
    border-radius: 16px;
    top: 0;
    left: 0;
    z-index: 0;
    opacity: 0.5;
    z-index: 0;
    border-radius: 15px;
  }

  .foto {
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    border-radius: 16px;
    position: absolute;
    top: 0;
    left: 0;
  }

  .name {
    color: white;
    font-size: 22px;
    font-weight: 500;
    z-index: 1;
    opacity: 0;
    transition: opacity 0.4s ease;
  }

  .cargo {
    font-size: 16px;
    color: #d25148;
    margin-bottom: 10px;
    z-index: 1;
    opacity: 0;
    transition: opacity 0.4s ease;
  }

  .description {
    color: white;
    z-index: 1;
    font-size: 14px;
    opacity: 0;
    transition: opacity 0.4s ease;
  }
`;

export { Item, Items, TeamStyle, Title };
