import styled from "styled-components";

const ContainerSlider = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;

  &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    display: block;
    background: linear-gradient(to right, #181818e8 0%, transparent 200%);
    top: 0;
    left: 0;
    z-index: 2;
  }

  @media only screen and (max-width: 430px) {
    height: 60vh;

    .__wrap {
      align-items: center !important;
      justify-content: center !important;
    }
  }
`;

const ItemStyle = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  opacity: 0;
  transition: opacity 0.4s ease;
  z-index: 1;

  @media only screen and (max-width: 430px) {
    height: 60vh;
  }

  &.active {
    opacity: 1;
  }
`;

export { ContainerSlider, ItemStyle };
