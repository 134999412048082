import {
  ButtonStyled,
  ButtonStyledFilter,
  ButtonStyledTransparent,
} from "./styled";

const Button = (props) => {
  return (
    <ButtonStyled
      bgColor={props.bgColor}
      paddingTb={props.paddingTb}
      {...props}
    >
      {props.children}
    </ButtonStyled>
  );
};

const ButtonFilter = (props) => {
  return (
    <ButtonStyledFilter
      bgColor={props.bgColor}
      paddingTb={props.paddingTb}
      type="button"
      {...props}
    >
      {props.children}
    </ButtonStyledFilter>
  );
};

const ButtonTransparent = (props) => {
  return (
    <ButtonStyledTransparent
      bgColor={props.bgColor}
      widths={props.widths}
      paddingTb={props.paddingTb}
    >
      {props.children}
    </ButtonStyledTransparent>
  );
};

export default Button;
export { ButtonFilter, ButtonTransparent };
