import styled from "styled-components";
import bg from "../../Assets/Images/bg.png";

const Title = styled.h1`
  color: white;
  font-weight: 500;
  font-size: 36px;
  line-height: 40px;
  width: 40%;
  margin-bottom: 20px;

  @media only screen and (max-width: 430px) {
    width: 100%;
    font-size: 25px;
  }

  span {
    color: #d25148;
  }
`;

const BackgroundStyle = styled.div`
  background: url(${bg});
  height: 820px;
  background-size: cover;
  background-position: center;

  @media only screen and (max-width: 430px) {
    height: auto;
    padding: 50px 20px;
  }

  .__wrap {
    display: flex;
    align-items: flex-start;
    flex-direction: column;

    justify-content: center;
  }
`;

export { BackgroundStyle, Title };
