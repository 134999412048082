import emailjs from "@emailjs/browser";
import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import TelefoneBrasileiroInput from "react-telefone-brasileiro";
import { ReactComponent as Check } from "../../Assets/SVGs/Check.svg";
import { ReactComponent as Senha } from "../../Assets/SVGs/Senha.svg";
import { ReactComponent as Ap } from "../../Assets/SVGs/ap.svg";
import { ReactComponent as Banheiros } from "../../Assets/SVGs/banheiro.svg";
import { ReactComponent as Cama } from "../../Assets/SVGs/cama.svg";
import { ReactComponent as Casa } from "../../Assets/SVGs/casa.svg";
import { ReactComponent as ImagensIcon } from "../../Assets/SVGs/images.svg";
import { ReactComponent as Location } from "../../Assets/SVGs/location.svg";
import { ReactComponent as Lote } from "../../Assets/SVGs/lote.svg";
import { ReactComponent as Planta } from "../../Assets/SVGs/planta.svg";
import { ReactComponent as Vagas } from "../../Assets/SVGs/vagas.svg";
import { ReactComponent as Varanda } from "../../Assets/SVGs/varanda.svg";
import Button from "../../Components/Buttons";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import ImagesShowComponent from "./ImagesShowComponent";
import MapCondo from "./MapCondo";
import { SobreImoveisComponent } from "./SobreImoveis";
import { VideoComponent } from "./Video";
import {
  Alt,
  BackgroundPage,
  Banner,
  Contents,
  Etapa,
  Flex,
  Form,
  Infra,
  Plantas,
  Separetor,
  Title,
} from "./styled";

const ContentBuildingVenture = () => {
  const [condo, setCondo] = useState([]);
  const [imoveis, setImoveis] = useState([]);
  const [isMap, setIsMap] = useState(false);
  const [plantaMain, setPlantaMain] = useState("");
  const [zoom, setZoom] = useState(false);
  const [telefone, setTelefone] = useState("");
  const [emailInput, setEmail] = useState("");
  const [nameUser, setNameUser] = useState("");

  function strToURL(str) {
    return str
      .toLowerCase()
      .trim()
      .replace(/[áàãâä]/g, "a")
      .replace(/[éèẽêë]/g, "e")
      .replace(/[íìĩîï]/g, "i")
      .replace(/[óòõôö]/g, "o")
      .replace(/[úùũûü]/g, "u")
      .replace(/ç/g, "c")
      .replace(/(\ |_)+/, " ")
      .replace(/(^-+|-+$)/, "")
      .replace(/[^a-z0-9]+/g, "-");
  }

  const escFunction = useCallback((e) => {
    if (e.keyCode == 27) {
      setZoom(false);
    }
  }, []);

  useEffect(() => {
    axios
      .get(
        "https://painel.freedomplanejamento.com.br/api/condominios?populate=*"
      )
      .then((result) => {
        const condominio = result.data.data.filter((item) => {
          if (
            strToURL("condo-" + item.attributes.Nome + "-" + item.id) ==
            window.location.pathname.split("/")[2]
          ) {
            return item;
          }
        })[0];

        console.log(condominio);

        if (condominio == undefined) {
          axios
            .get(
              "https://painel.freedomplanejamento.com.br/api/imoveis?populate=Endereco&populate=Diferenciais&populate=Fotos&populate=Infraestruturas&populate=Comodos&populate=condominio.Infraestruturas&populate=condominio.Diferenciais&populate=condominio.Fotos"
            )
            .then((result) => {
              const imovel = result.data.data.filter((item) => {
                if (
                  strToURL(
                    (typeof item.attributes.Condominio_Fechado !== "undefined"
                      ? "imov"
                      : "condo") +
                      "-" +
                      item.attributes.Nome +
                      "-" +
                      item.id
                  ) == window.location.pathname.split("/")[2]
                ) {
                  return item;
                }
              })[0];

              setCondo(imovel);
            });
        } else {
          setCondo(condominio);
          setPlantaMain(
            "https://painel.freedomplanejamento.com.br" +
              condominio.attributes?.Plantas?.data[0].attributes.url
          );
        }
      });
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);

    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const sendEmail = () => {
    let formData = new FormData();
    formData.append("condominio_nome", condo.nome);
    formData.append("full_name", nameUser);
    // formData.append("email", emailInput);
    // formData.append("phone", telefone);
    formData.append("message", "Desejo saber mais sobre " + condo.nome);
    formData.append("source", "Freedom Site");
    formData.append("subject", "Desejo saber mais sobre " + condo.nome);
    formData.append("property_code", condo.codigo);

    const button = document.querySelector(".subtn");
    button.textContent = "Enviando...";
    button.classList.add("__actived");

    window.location.replace(
      `https://api.whatsapp.com/send?phone=5511934099014&text=Ol%C3%A1!%20Me%20chamo%20${nameUser}%20e%20vim%20atrav%C3%A9s%20do%20site%20de%20voc%C3%AAs.%20Quero%20come%C3%A7ar%20a%20planejar%20meu%20sonho%20com%20a%20Freedom!`
    );

    // setTimeout(() => {
    //   emailjs
    //     .send(
    //       "hostinger",
    //       "template2",
    //       {
    //         condominio_nome: condo.nome,
    //         name: nameUser,
    //         email: emailInput,
    //         phone: telefone,
    //         message: "",
    //       },
    //       "APyoPH2-Db_Tq8M8j"
    //     )
    //     .then((resp) => {
    //       if (resp.status === 200) {
    //         alert("Mensagem enviada com sucesso!");
    //         setEmail("");
    //         setNameUser("");
    //         button.textContent = "Enviar Contato";
    //         button.classList.remove("__actived");
    //       } else {
    //         alert("Ops, algo de errado aconteceu :(");
    //       }
    //     });
    //   axios
    //     .post(
    //       "https://freedomplanejamento.com.br/api.php?method=leads",
    //       formData
    //     )
    //     .then((result) =>
    //       result.data.success
    //         ? console.log("Lead Registrado com Sucesso!")
    //         : console.log(result.data)
    //     );
    // }, 1000);

    setTimeout(() => {
      button.textContent = "Enviar Contato";
      button.classList.remove("__actived");
    }, 2000);
  };

  // return false;

  return (
    <BackgroundPage>
      <div
        tabIndex="0"
        onClick={() => setZoom(false)}
        className={zoom ? "ZoomPlantas _actived" : "ZoomPlantas"}
      >
        <img src={plantaMain} />
      </div>
      <Header />
      <Banner>
        <div className="__wrap">
          <Flex direction={"column"} alignItems={"flex-start"} heights={"100%"}>
            <Etapa>{condo.attributes?.Status}</Etapa>
            <Title>{condo.attributes?.Nome}</Title>
            <Flex className="tipo">
              {condo.attributes?.Tipo == "Apartamento" ? (
                <Ap />
              ) : condo.attributes?.Tipo == "Casa" ? (
                <Casa />
              ) : (
                <Lote />
              )}
              <p>{condo.attributes?.Tipo}</p>
            </Flex>
            <Flex>
              <Location />

              <p>
                {condo.attributes?.Endereco
                  ? condo.attributes?.Endereco.Rua +
                    ", " +
                    condo.attributes?.Endereco.Numero +
                    " - " +
                    condo.attributes?.Endereco.Bairro
                  : ""}{" "}
                - {condo.attributes?.Cidade}/{condo.attributes?.Estado}
              </p>
            </Flex>
            {condo.attributes?.Mostrar_Preco ? (
              condo.attributes?.Faixas ? (
                <p
                  style={{
                    fontWeight: "bold",
                    fontSize: "22px",
                    marginTop: "15px",
                  }}
                >
                  R$ {condo.attributes.Faixas.Valores.split("-")[0]} - R${" "}
                  {condo.attributes.Faixas.Valores.split("-")[1]}
                </p>
              ) : (
                <p
                  style={{
                    fontWeight: "bold",
                    fontSize: "22px",
                    marginTop: "15px",
                  }}
                >
                  R$ {condo.attributes.Valor}
                </p>
              )
            ) : (
              ""
            )}
          </Flex>
        </div>
      </Banner>
      <Alt style={{ marginBottom: "20px" }}>
        {/* {isMap && condo?.latitude != null ? (
          <MapCondo lat={condo?.latitude} long={condo?.longitude} />
        ) : (
          <ImagesShowComponent condo={condo} />
          )} */}
        <ImagesShowComponent condo={condo} />
      </Alt>
      <Flex className="__wrap mobile-wrap" justifyContent={"flex-start"}>
        <Flex
          onClick={() => setIsMap(!isMap)}
          className={!isMap ? " _actived imagensIcones" : "imagensIcones"}
        >
          <ImagensIcon />(
          {condo.attributes?.condominio?.data
            ? condo.attributes?.Fotos?.data.length +
              (condo.attributes?.condominio?.data
                ? condo.attributes?.condominio?.data.attributes?.Fotos.data
                    ?.length
                : 0)
            : condo.attributes?.Fotos?.data.length}
          )
        </Flex>
        {/* <Flex
          onClick={() => setIsMap(!isMap)}
          className={isMap ? " _actived imagensIcones" : "imagensIcones"}
        >
          <Location />
          Ver Mapa
        </Flex> */}
      </Flex>
      <div className="__wrap mobile-wrap">
        <Flex
          direction="row-reverse"
          justifyContent="flex-start"
          alignItems="flex-start"
          className="mobile-column"
        >
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              sendEmail();
            }}
          >
            <p className="titulo-form">
              <span>Gostei!</span> <br />
              Quero mais informações
            </p>
            <label>
              <Flex direction={"row"}>
                <legend>Nome: </legend>
                <input
                  type="text"
                  name="name"
                  value={nameUser}
                  onChange={(ev) => setNameUser(ev.target.value)}
                  placeholder="Obrigatório"
                  required
                />
              </Flex>
            </label>
            {/* <label>
              <Flex direction={"row"}>
                <legend>Telefone: </legend>
                <TelefoneBrasileiroInput
                  type="tel"
                  name="phone"
                  placeholder="Obrigatório"
                  value={telefone}
                  onChange={(ev) => setTelefone(ev.target.value)}
                  temDDD
                  required
                />
              </Flex>
            </label>
            <label>
              <Flex direction={"row"}>
                <legend>E-mail: </legend>
                <input
                  name="email"
                  type="email"
                  value={emailInput}
                  onChange={(ev) => setEmail(ev.target.value)}
                  placeholder="Obrigatório"
                  required
                />
              </Flex>
            </label> */}
            <Button className="subtn" type="submit">
              Enviar Contato
            </Button>
          </Form>
          <Contents>
            {condo.attributes?.Descricao != "" ? (
              <p style={{ wordWrap: "anywhere", width: "100%" }}>
                {condo.attributes?.Descricao}
              </p>
            ) : (
              ""
            )}
            {<SobreImoveisComponent condo={condo} />}
            {<VideoComponent condo={condo} />}
            {condo.attributes?.Infraestruturas?.length > 0 ? (
              <Infra>
                <h3>
                  Infraestruturas{" "}
                  {condo.attributes?.condominio?.data ? "do imóvel" : ""}
                </h3>
                <Separetor />
                <Flex flexWrap="wrap" justifyContent="flex-start">
                  {condo.attributes?.Infraestruturas.map((item) => {
                    if (item.Infraestrutura !== null) {
                      if (
                        item.Infraestrutura.trim().toLowerCase() !=
                        "varanda gourmet"
                      ) {
                        return (
                          <Flex
                            id={item.id}
                            className="items"
                            justifyContent="flex-start"
                          >
                            <Check />
                            {item.Infraestrutura}
                          </Flex>
                        );
                      }
                    }
                  })}
                </Flex>
              </Infra>
            ) : (
              ""
            )}
            {condo.attributes?.Plantas?.data?.length > 0 ? (
              <Plantas>
                <h3>Plantas</h3>
                <Separetor />
                <div
                  className="plantaMain"
                  onClick={() => setZoom(true)}
                  style={{ backgroundImage: `url(${plantaMain})` }}
                ></div>
                <div className="plantasCounters">
                  {condo.attributes?.Plantas?.data.map((planta) => (
                    <div
                      className={
                        plantaMain ==
                        "https://painel.freedomplanejamento.com.br" +
                          planta.attributes?.url
                          ? "counter _actived"
                          : "counter"
                      }
                      style={{
                        backgroundImage: `url(https://painel.freedomplanejamento.com.br${planta.attributes?.url})`,
                      }}
                      onClick={() =>
                        setPlantaMain(
                          "https://painel.freedomplanejamento.com.br" +
                            planta.attributes?.url
                        )
                      }
                    ></div>
                  ))}
                </div>
              </Plantas>
            ) : (
              ""
            )}
            {condo.attributes?.Diferenciais?.length > 0 ? (
              <Infra>
                <h3>
                  Diferenciais{" "}
                  {condo.attributes?.condominio?.data ? "do imóvel" : ""}
                </h3>
                <Separetor />
                <Flex flexWrap="wrap" justifyContent="flex-start">
                  {condo.attributes?.Diferenciais.map((item) => {
                    return (
                      <Flex className="items" justifyContent="flex-start">
                        <Check />
                        {item.Diferencial !== null ? item.Diferencial : ""}
                      </Flex>
                    );
                  })}
                </Flex>
              </Infra>
            ) : (
              ""
            )}
            {condo.attributes?.condominio?.data != undefined &&
            (condo.attributes?.condominio?.data != undefined
              ? condo.attributes?.condominio?.data.attributes?.Diferenciais
                  .length > 0
              : false) ? (
              <Infra>
                <h3>Diferenciais do Condominio</h3>
                <Separetor />
                <Flex flexWrap="wrap" justifyContent="flex-start">
                  {condo.attributes?.condominio?.data.attributes?.Diferenciais.map(
                    (item) => {
                      return (
                        <Flex className="items" justifyContent="flex-start">
                          <Check />
                          {item.Diferencial !== null ? item.Diferencial : ""}
                        </Flex>
                      );
                    }
                  )}
                </Flex>
              </Infra>
            ) : (
              ""
            )}
            {condo.attributes?.condominio?.data != undefined &&
            (condo.attributes?.condominio?.data != undefined
              ? condo.attributes?.condominio?.data.attributes?.Infraestruturas
                  .length > 0
              : false) ? (
              <Infra>
                <h3>Infraestruturas do Condominio</h3>
                <Separetor />
                <Flex flexWrap="wrap" justifyContent="flex-start">
                  {condo.attributes?.condominio?.data.attributes?.Infraestruturas.map(
                    (item) => {
                      if (item.Infraestrutura !== null) {
                        if (
                          item.Infraestrutura.trim().toLowerCase() !=
                          "varanda gourmet"
                        ) {
                          return (
                            <Flex
                              id={item.id}
                              className="items"
                              justifyContent="flex-start"
                            >
                              <Check />
                              {item.Infraestrutura}
                            </Flex>
                          );
                        }
                      }
                    }
                  )}
                </Flex>
              </Infra>
            ) : (
              ""
            )}
            {
              // <Infra>
              //   <h3>Ficha Técnica</h3>
              //   <Separetor />
              //   <Flex flexWrap="wrap" justifyContent="flex-start">
              //     <ul>
              //       <li>
              //         Nome do empreendimento: <span>{condo.nome}</span>
              //       </li>
              //       <li>
              //         Localização:{" "}
              //         <span>
              //           {condo.endereco_logradouro}, {condo.endereco_numero} -{" "}
              //           {condo.endereco_bairro} - {condo.endereco_cidade}/
              //           {condo.endereco_estado}
              //         </span>
              //       </li>
              //       {imoveis[0]?.area_total ? (
              //         <li>
              //           Tamanho:{" "}
              //           <span>
              //             {imoveis[0]?.area_total + imoveis[0]?.medida}
              //             {getAreaTotalDois().length != 0 ? " - " : ""}
              //             {getAreaTotalDois().join(` - `)}
              //           </span>
              //         </li>
              //       ) : (
              //         ""
              //       )}
              //       <li>
              //         Previsão de entrega:{" "}
              //         <span>
              //           {condo.entrega_mes}/{condo.entrega_ano}
              //         </span>
              //       </li>
              //       {condo.construtora ? (
              //         <li>
              //           Construtora: <span>{condo.construtora}</span>
              //         </li>
              //       ) : (
              //         ""
              //       )}
              //       {condo.incorporadora ? (
              //         <li>
              //           Incorporadora: <span>{condo.incorporadora}</span>
              //         </li>
              //       ) : (
              //         ""
              //       )}
              //       {condo.registro_incorporacao ? (
              //         <li>
              //           Registro da Incorporacao:{" "}
              //           <span>{condo.registro_incorporacao}</span>
              //         </li>
              //       ) : (
              //         ""
              //       )}
              //       {condo.administradora ? (
              //         <li>
              //           Administradora: <span>{condo.administradora}</span>
              //         </li>
              //       ) : (
              //         ""
              //       )}
              //     </ul>
              //   </Flex>
              // </Infra>
            }
          </Contents>
        </Flex>
      </div>
      <Footer />
    </BackgroundPage>
  );
};

export default ContentBuildingVenture;
