import styled from "styled-components";
const Title = styled.h1`
  color: #575656;
  font-weight: 500;
  font-size: 36px;
  line-height: 40px;
  width: 100%;
  margin-bottom: 20px;

  @media only screen and (max-width: 430px) {
    font-size: 26px;
  }

  span {
    color: #d25148;
  }
`;

const BackgroundStyle = styled.div`
  background-color: rgb(211, 216, 219);
  padding: 100px 0;
  background-size: cover;

  @media only screen and (max-width: 430px) {
    padding: 50px 20px;
  }

  .__wrap {
    display: flex;
    align-items: center;
    justify-content: center;

    @media only screen and (max-width: 430px) {
      flex-direction: column;
    }
  }
`;

const Text = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  margin-left: 100px;

  @media only screen and (max-width: 430px) {
    margin-left: 0px;
  }
`;

export { BackgroundStyle, Text, Title };
