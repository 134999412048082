import styled from "styled-components";
import banner from "../../Assets/Images/contato-bg.png";

// em = contato@freedomplanejamento.com.br
// ps: Fre3dom@

const Banner = styled.div`
  height: 80vh;
  background-image: url(${banner});
  background-size: cover;
  background-position: center;

  @media only screen and (max-width: 430px) {
    height: 50vh;
    padding: 20px;
  }
`;

const Title = styled.h1`
  color: white;
  font-weight: 500;
  font-size: 56px;
  line-height: 40px;
  width: 100%;
  text-align: center;
  margin-bottom: 20px;

  h2 {
    font-size: 22px;
    width: 50%;
    font-weight: 600;
    margin-top: 32px;
  }

  span {
    color: #d25148;
  }

  @media only screen and (max-width: 430px) {
    font-size: 36px;

    h2 {
      font-size: 18px;
      line-height: 20px;
      margin-top: 10px;
      width: 100%;
    }
  }
`;

const Flex = styled.div`
  display: flex;
  height: 100%;

  .txts {
    margin-right: 50px;
    div .img1 {
      margin-right: 20px;
    }
  }

  .parede {
    height: 617px;
  }

  ${(props) => {
    let propsStyles = props.direction
      ? `flex-direction: ${props.direction};`
      : "flex-direction: column;";

    propsStyles += props.alignItems
      ? `align-items: ${props.alignItems};`
      : "align-items: center;";

    propsStyles += props.justifyContent
      ? `justify-content: ${props.justifyContent};`
      : "justify-content: center;";

    return propsStyles;
  }}

  @media only screen and (max-width: 430px) {
    &.mobile-column {
      flex-direction: column;
    }
  }
`;

const FormDiv = styled.div`
  background: linear-gradient(to right, white 50%, #d25148 50%);
  padding: 50px 0;
  @media only screen and (max-width: 430px) {
    padding: 20px;
    background: linear-gradient(to bottom, white 50%, #d25148 50%);
  }
`;

const Description = styled.div`
  color: #575656;
  font-size: 16px;
  @media only screen and (max-width: 430px) {
    br {
      display: none;
    }
  }
`;

const TitleT = styled.div`
  font-size: 36px;
  font-weight: 600;
  color: #575656;
  text-align: left;
  margin-bottom: 30px;
  line-height: 40px;

  span {
    color: #d25148;
  }
`;

const Icons = styled.div`
  color: #575656;
  img {
    margin-right: 10px;
  }
  div {
    margin-top: 24px;
  }

  @media only screen and (max-width: 430px) {
    font-size: 14px;

    &:nth-child(6) {
      div,
      p {
        margin-top: 10px !important;
      }
    }
  }
`;

const Form = styled.form`
  display: flex;
  padding: 100px;
  align-items: flex-end;
  justify-content: center;
  flex-direction: column;
  background-color: white;
  width: 636px;
  height: 676px;
  box-shadow: 0 0 30px 2px rgba(0, 0, 0, 0.15);
  border-radius: 10px;

  @media only screen and (max-width: 430px) {
    width: 100%;
    padding: 20px;
    height: auto;
    margin-top: 50px;
  }

  label:not(.privacidade), .checks {
    width: 100%;
    margin-bottom: 30px;

    legend {
      font-size: 22px;
      font-weight: 600;
      color: #c4c4c4;
      margin-right: 20px;
    }

    @media only screen and (max-width: 430px) {
      legend {
        font-size: 18px;
      }
    }

    input,
    textarea {
      border-bottom: 2px solid #c4c4c4;
      text-align: right;
      font-weight: 600;
      color: #181818;
      width: 70%;
      height: 20px;
    }

    input[type="checkbox"] {
      width 16px;
      height 16px;
      border: 2px solid #c4c4c4;
      border-radius: 2px;
      position: relative;
      cursor: pointer;
      margin-left: 10px;
      transition: 0.2s background-color ease, 0.2s border ease;

      &:checked {
        background-color: #d25148;
        border: 2px solid #d25148;

        &:after {
          opacity: 1;
        }
      }

      &:after {
        content: '';
        width: 5px;
        height: 8px;
        display: block;
        border-right: 2px solid white;
        border-bottom: 2px solid white;
        position: absolute;
        top: 35%;
        left: 50%;
        transition: 0.2s opacity ease;
        opacity: 0;
        transform: translate(-50%, -50%) rotate(35deg);

      } 

    }
    
  }
  .privacidade {
    width: 100%;
    margin-bottom: 20px;

    a {
      text-decoration: underline;
      transition: color 0.2s ease;

      &:hover {
        color: #d25148;
      }
    }

    legend {
      font-size: 12px;
      text-align: right;
      color: #575656;

      @media only screen and (max-width: 430px) {
        width: 90%;
          br {
            display: none !important;
          }
      }
    }

    input[type="checkbox"] {
      width 16px;
      height 16px;
      cursor: pointer;
      border: 2px solid #c4c4c4;
      border-radius: 2px;
      position: relative;
      margin-left: 10px;
      transition: 0.2s background-color ease, 0.2s border ease;

      &:checked {
        background-color: #d25148;
        border: 2px solid #d25148;

        &:after {
          opacity: 1;
        }
      }

      &:after {
        content: '';
        width: 5px;
        height: 8px;
        display: block;
        border-right: 2px solid white;
        border-bottom: 2px solid white;
        position: absolute;
        top: 35%;
        left: 50%;
        transition: 0.2s opacity ease;
        opacity: 0;
        transform: translate(-50%, -50%) rotate(35deg);

      } 
  }
`;

export { Banner, Description, Flex, Form, FormDiv, Icons, Title, TitleT };
