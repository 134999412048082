import styled from "styled-components";
import bg from "../../Assets/Images/sobre-bg.png";

const Title = styled.h1`
  color: white;
  font-weight: 500;
  font-size: 36px;
  line-height: 40px;
  width: 100%;
  margin-bottom: 20px;

  h2 {
    font-size: 28px;
    width: 50%;
    font-weight: normal;
    margin-top: 32px;
  }

  span {
    color: #d25148;
  }

  @media only screen and (max-width: 430px) {
    font-size: 30px;
    br {
      display: none;
    }
    h2 {
      width: 100%;
    }
  }
`;

const TalkAbout = styled.div`
  background: url(${bg});
  padding: 100px 0;
  position: relative;
  background-size: cover;

  @media only screen and (max-width: 430px) {
    padding: 20px;
  }

  &:after {
    position: absolute;
    content: "";
    width: 100%;
    display: block;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 0;
    background: linear-gradient(to right, black -30%, transparent 200%);
  }

  .__wrap {
    z-index: 1;
  }

  p {
    color: white;
    margin-bottom: 40px;
  }

  @media only screen and (max-width: 430px) {
    p br {
      display: none;
    }
  }
`;

export { TalkAbout, Title };
