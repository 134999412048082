export const mascaraMoeda = (input) => {
  if (typeof input === "undefined" || input == null) return 0;

  let valor = "";

  if (typeof input.value !== "undefined") {
    valor = input.value;
  } else {
    valor = input;
  }

  if (valor.length == 2) return valor;

  let v = valor;
  v = v.replace(/\D/g, "");
  v = v.replace(/(\d{1,2})$/, ",$1");
  v = v.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
  return v;
};

export const mascaraMetro = (input) => {
  if (typeof input === "undefined" || input == null) return 0;

  let valor = "";

  if (typeof input.value !== "undefined") {
    valor = input.value;
  } else {
    valor = input;
  }

  if (valor.length == 2) return valor;

  let v = valor;
  v = v.replace(/\D/g, "");
  v = v.replace(/(\d{1,2})$/, ".$1");
  return v;
};

// export const mascaraMoeda = (event) => {
//   if (event.target.value == "R$ 0,0") {
//     return "a";
//   }
//   const onlyDigits = event.target.value
//     .split("")
//     .filter((s) => /\d/.test(s))
//     .join("")
//     .padStart(3, "0");
//   const digitsFloat = onlyDigits.slice(0, -2) + "." + onlyDigits.slice(-2);
//   return maskCurrency(digitsFloat);
// };

// const maskCurrency = (valor, locale = "pt-BR", currency = "BRL") => {
//   return new Intl.NumberFormat(locale, {
//     style: "currency",
//     currency,
//   }).format(valor);
// };
