import styled from "styled-components";

const ButtonStyled = styled.button`
  color: white;
  padding-left: 24px;
  border-radius: 4px;
  font-weight: 500;
  padding-right: 24px;
  transition: transform 0.5s ease;

  ${(props) => {
    let propsStyles = props.fontSize ? `font-size: ${props.fontSize};` : "";

    propsStyles += props.paddingTb
      ? `
          padding-top: ${props.paddingTb};
          padding-bottom: ${props.paddingTb};
        `
      : "padding-top: 13px; padding-bottom: 13px; ";

    propsStyles += props.bgColor
      ? `
          background-color: ${props.bgColor};
        `
      : "background-color: #D25148;";

    return propsStyles;
  }}

  &:hover {
    transform: scale(1.1);
  }

  &.__actived {
    pointer-events: none;
  }
`;

const ButtonStyledFilter = styled.button`
  color: white;
  padding-left: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 4px;
  font-weight: 500;
  padding-right: 10px;
  border: 2px solid #D25148;
  transition: transform 0.5s ease;

  svg {
    margin-right: 5px;
  }

  ${(props) => {
    let propsStyles = props.fontSize ? `font-size: ${props.fontSize};` : "";

    propsStyles += props.paddingTb
      ? `
          padding-top: ${props.paddingTb};
          padding-bottom: ${props.paddingTb};
        `
      : "padding-top: 10px; padding-bottom: 10px; ";

    propsStyles += props.bgColor
      ? `
          background-color: ${props.bgColor};
        `
      : "background-color: #D25148;";

    return propsStyles;
  }}

  &:hover {
    transform: scale(1.1);
  }

  &.__actived {
    pointer-events: none;
  }
`;

const ButtonStyledTransparent = styled.button`
  color: white;
  padding-left: 24px;
  border-radius: 4px;
  font-weight: 500;
  border: 2px solid #181818;
  padding-right: 24px;
  transition: transform 0.5s ease, background-color 0.5s ease, border 0.5s ease;

  ${(props) => {
    let propsStyles = props.fontSize ? `font-size: ${props.fontSize};` : "";

    propsStyles += props.paddingTb
      ? `
          padding-top: ${props.paddingTb};
          padding-bottom: ${props.paddingTb};
        `
      : "padding-top: 13px; padding-bottom: 13px; ";

    propsStyles += props.bgColor
      ? `
          background-color: ${props.bgColor};
        `
      : "background-color: transparent;";

    propsStyles += props.widths
      ? `
          width: ${props.widths};
        `
      : "";

    return propsStyles;
  }}

  &:hover {
    transform: scale(1.1);
    background-color: #d25148;
  }

  &.__actived {
    pointer-events: none;
  }
`;

export { ButtonStyled, ButtonStyledFilter, ButtonStyledTransparent };

