import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import WhatsYourDream from "../../Components/WhatsYourDream";
import { Banner, Title, Flex } from "./styled";

const Thanks = () => {
  return (
    <>
      <Header />
      <Banner>
        <Flex>
          <Title>
            Agredecemos seu Contato!
            <br />
            <span>
              Muito em breve um de nossos profissionais <br />
              entrará em contato com você!
            </span>
          </Title>
        </Flex>
      </Banner>
      <WhatsYourDream />
      <Footer />
    </>
  );
};

export default Thanks;
