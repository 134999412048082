export const filters = (imoveis, condominios, props) => {
  let imoveisFiltered = imoveis;
  let condominiosFiltered = condominios;

  if (props.isApartamento || props.isCasa || props.isTerreno) {
    imoveisFiltered = filterTipoImoveis(imoveisFiltered, props);
    condominiosFiltered = filterTipoCondominios(condominiosFiltered, props);
  }

  if (props.isLancamento || props.isMorar || props.isConstrucao) {
    imoveisFiltered = filterStatusImoveis(imoveisFiltered, props);
    condominiosFiltered = filterStatusCondominios(condominiosFiltered, props);
  }

  if (props.isCondo) {
    imoveisFiltered = imoveisFiltered.filter((imov) => {
      return imov.attributes.Condominio_Fechado ? true : false;
    });
    condominiosFiltered = condominiosFiltered.filter((imov) => {
      return true;
    });
  }

  if (props.location.length > 0) {
    imoveisFiltered = imoveisFiltered.filter((imov) => {
      const cityFiltered = props.location.filter((city) => {
        if (imov.attributes.Cidade == city.value) {
          return true;
        }
      });

      if (cityFiltered.length > 0) {
        return true;
      }
    });

    condominiosFiltered = condominiosFiltered.filter((imov) => {
      const cityFiltered = props.location.filter((city) => {
        if (imov.attributes.Cidade == city.value) {
          return true;
        }
      });

      if (cityFiltered.length > 0) {
        return true;
      }
    });
  } else {
    imoveisFiltered = imoveisFiltered;
    condominiosFiltered = condominiosFiltered;
  }

  if (props.minValue || props.maxValue) {
    const valorMaximo =
      props.maxValue == ""
        ? 999999999.0
        : parseFloat(props.maxValue.replace(".", "").replace(",", "."));
    const valorMinimo =
      props.minValue == ""
        ? 0.0
        : parseFloat(props.minValue.replace(".", "").replace(",", "."));

    imoveisFiltered = imoveisFiltered.filter((imov) => {
      if (
        parseFloat(imov.attributes.Valor.replace(".", "").replace(",", ".")) >=
          valorMinimo &&
        parseFloat(imov.attributes.Valor.replace(".", "").replace(",", ".")) <=
          valorMaximo &&
        imov.attributes.Valor != null &&
        imov.attributes.Mostrar_Preco == true
      ) {
        return true;
      }
    });
  }

  if (props.minValue || props.maxValue) {
    const valorMaximo =
      props.maxValue == ""
        ? 999999999.0
        : parseFloat(props.maxValue.replace(".", "").replace(",", "."));
    const valorMinimo =
      props.minValue == ""
        ? 0.0
        : parseFloat(props.minValue.replace(".", "").replace(",", "."));

    condominiosFiltered = condominiosFiltered.filter((imov) => {
      if (
        ((valorMinimo <=
          parseFloat(
            imov.attributes.Faixas.Valores.split("-")[0]
              .replace(".", "")
              .replace(",", ".")
          ) &&
          parseFloat(
            imov.attributes.Faixas.Valores.split("-")[0]
              .replace(".", "")
              .replace(",", ".")
          ) <= valorMaximo) ||
          (valorMinimo <=
            parseFloat(
              imov.attributes.Faixas.Valores.split("-")[1]
                .replace(".", "")
                .replace(",", ".")
            ) &&
            parseFloat(
              imov.attributes.Faixas.Valores.split("-")[1]
                .replace(".", "")
                .replace(",", ".")
            ) <= valorMaximo)) &&
        imov.attributes.Mostrar_Preco == true
      ) {
        return true;
      }
    });
  }

  if (props.minMeters || props.maxMeters) {
    const metroMaximo =
      props.maxMeters == "" ? 999999999.0 : parseFloat(props.maxMeters);
    const metroMinimo =
      props.minMeters == "" ? 0.0 : parseFloat(props.minMeters);

    imoveisFiltered = imoveisFiltered.filter((imov) => {
      if (
        parseFloat(imov.attributes.Metragem) >= metroMinimo &&
        parseFloat(imov.attributes.Metragem) <= metroMaximo
      ) {
        return true;
      }
    });
  }

  if (props.minMeters || props.maxMeters) {
    const metroMaximo2 =
      props.maxMeters == "" ? 999999999.0 : parseFloat(props.maxMeters);
    const metroMinimo2 =
      props.minMeters == "" ? 0.0 : parseFloat(props.minMeters);

    condominiosFiltered = condominiosFiltered.filter((imov) => {
      if (
        parseFloat(imov.attributes.Faixas.Metragens.split("-")[0]) <=
          metroMinimo2 &&
        parseFloat(imov.attributes.Faixas.Metragens.split("-")[1]) >=
          metroMaximo2
      ) {
        return true;
      }
    });
  }

  if (props.quartosQtd) {
    if (props.quartosQtd == 4) {
      imoveisFiltered = imoveisFiltered.filter((imov) => {
        if (imov.attributes.Comodos[0].Quartos >= 4) {
          return true;
        }
      });
    } else {
      imoveisFiltered = imoveisFiltered.filter((imov) => {
        if (imov.attributes.Comodos[0].Quartos == props.quartosQtd) {
          return true;
        }
      });
    }
  }

  if (props.quartosQtd) {
    if (props.quartosQtd == 4) {
      condominiosFiltered = condominiosFiltered.filter((imov) => {
        if (!imov.attributes.Faixas.Quartos.includes("-")) {
          if (parseInt(imov.attributes.Faixas.Quartos) >= 4) {
            return true;
          }
        }
        if (parseInt(imov.attributes.Faixas.Quartos.split("-")[1]) >= 4) {
          return true;
        }
      });
    } else {
      condominiosFiltered = condominiosFiltered.filter((imov) => {
        if (!imov.attributes.Faixas.Quartos.includes("-")) {
          if (parseInt(imov.attributes.Faixas.Quartos) == props.quartosQtd) {
            return true;
          }
        }
        if (
          parseInt(imov.attributes.Faixas.Quartos.split("-")[0]) <=
            props.quartosQtd &&
          parseInt(imov.attributes.Faixas.Quartos.split("-")[1]) >=
            props.quartosQtd
        ) {
          return true;
        }
      });
    }
  }

  if (props.banheirosQtd) {
    if (props.banheirosQtd == 4) {
      imoveisFiltered = imoveisFiltered.filter((imov) => {
        if (imov.attributes.Comodos[0].Banheiros >= 4) {
          return true;
        }
      });
    } else {
      imoveisFiltered = imoveisFiltered.filter((imov) => {
        if (imov.attributes.Comodos[0].Banheiros == props.banheirosQtd) {
          return true;
        }
      });
    }
  }

  if (props.banheirosQtd) {
    if (props.banheirosQtd == 4) {
      condominiosFiltered = condominiosFiltered.filter((imov) => {
        if (!imov.attributes.Faixas.Banheiros.includes("-")) {
          if (parseInt(imov.attributes.Faixas.Banheiros) >= 4) {
            return true;
          }
        }
        if (parseInt(imov.attributes.Faixas.Banheiros.split("-")[1]) >= 4) {
          return true;
        }
      });
    } else {
      condominiosFiltered = condominiosFiltered.filter((imov) => {
        if (!imov.attributes.Faixas.Banheiros.includes("-")) {
          if (
            parseInt(imov.attributes.Faixas.Banheiros) == props.banheirosQtd
          ) {
            return true;
          }
        }
        if (
          parseInt(imov.attributes.Faixas.Banheiros.split("-")[0]) <=
            props.banheirosQtd &&
          parseInt(imov.attributes.Faixas.Banheiros.split("-")[1]) >=
            props.banheirosQtd
        ) {
          return true;
        }
      });
    }
  }

  if (props.vagasQtd) {
    if (props.vagasQtd == 4) {
      imoveisFiltered = imoveisFiltered.filter((imov) => {
        if (imov.attributes.Comodos[0].Vagas >= 4) {
          return true;
        }
      });
    } else {
      imoveisFiltered = imoveisFiltered.filter((imov) => {
        if (imov.attributes.Comodos[0].Vagas == props.vagasQtd) {
          return true;
        }
      });
    }
  }

  if (props.vagasQtd) {
    if (props.vagasQtd == 4) {
      condominiosFiltered = condominiosFiltered.filter((imov) => {
        if (!imov.attributes.Faixas.Vagas.includes("-")) {
          if (parseInt(imov.attributes.Faixas.Vagas) >= 4) {
            return true;
          }
        }
        if (parseInt(imov.attributes.Faixas.Vagas.split("-")[1]) >= 4) {
          return true;
        }
      });
    } else {
      condominiosFiltered = condominiosFiltered.filter((imov) => {
        if (!imov.attributes.Faixas.Vagas.includes("-")) {
          if (parseInt(imov.attributes.Faixas.Vagas) == props.vagasQtd) {
            return true;
          }
        }
        if (
          parseInt(imov.attributes.Faixas.Vagas.split("-")[0]) <=
            props.vagasQtd &&
          parseInt(imov.attributes.Faixas.Vagas.split("-")[1]) >= props.vagasQtd
        ) {
          return true;
        }
      });
    }
  }

  if (props.dif.length > 0) {
    imoveisFiltered = imoveisFiltered.filter((imov) => {
      const difFilt = imov.attributes.Diferenciais.filter((dif) => {
        if (props.dif.includes(dif.Diferencial)) {
          return true;
        }
      });

      let difFiltWithCondo = [];
      if (imov.attributes.condominio) {
        difFiltWithCondo =
          imov.attributes.condominio.attributes.Diferenciais.filter((dif) => {
            if (props.dif.includes(dif.Diferencial)) {
              return true;
            }
          });
      }
      if (difFilt.length > 0 || difFiltWithCondo.length > 0) {
        return true;
      }
    });
  }

  if (props.dif.length > 0) {
    condominiosFiltered = condominiosFiltered.filter((imov) => {
      const difFilt = imov.attributes.Diferenciais.filter((dif) => {
        if (props.dif.includes(dif.Diferencial)) {
          return true;
        }
      });

      if (difFilt.length > 0) {
        return true;
      }
    });
  }

  return [imoveisFiltered, condominiosFiltered];
};

const filterStatusImoveis = (imoveisFilt, props) => {
  let imoveisFiltered;

  imoveisFiltered = imoveisFilt.filter((imov) => {
    if (props.isLancamento) {
      if (imov.attributes.Status == "Lançamento") {
        return true;
      }
    }
  });

  imoveisFiltered = [
    ...imoveisFiltered,
    ...imoveisFilt.filter((imov) => {
      if (props.isMorar) {
        if (imov.attributes.Status == "Pronto pra morar") {
          return true;
        }
      }
    }),
  ];

  imoveisFiltered = [
    ...imoveisFiltered,
    ...imoveisFilt.filter((imov) => {
      if (props.isConstrucao) {
        if (imov.attributes.Status == "Em construção") {
          return true;
        }
      }
    }),
  ];

  return imoveisFiltered;
};
const filterStatusCondominios = (condominiosFilt, props) => {
  let condominiosFiltered;
  condominiosFiltered = condominiosFilt.filter((imov) => {
    if (props.isLancamento) {
      if (imov.attributes.Status == "Lançamento") {
        return true;
      }
    }
  });

  condominiosFiltered = [
    ...condominiosFiltered,
    ...condominiosFilt.filter((imov) => {
      if (props.isMorar) {
        if (imov.attributes.Status == "Pronto pra morar") {
          return true;
        }
      }
    }),
  ];

  condominiosFiltered = [
    ...condominiosFiltered,
    ...condominiosFilt.filter((imov) => {
      if (props.isConstrucao) {
        if (imov.attributes.Status == "Em construção") {
          return true;
        }
      }
    }),
  ];

  return condominiosFiltered;
};

const filterTipoImoveis = (imoveisFilt, props) => {
  let imoveisFiltered;

  imoveisFiltered = imoveisFilt.filter((imov) => {
    if (props.isApartamento) {
      if (imov.attributes.Tipo == "Apartamento") {
        return true;
      }
    }
  });

  imoveisFiltered = [
    ...imoveisFiltered,
    ...imoveisFilt.filter((imov) => {
      if (props.isCasa) {
        if (imov.attributes.Tipo == "Casa") {
          return true;
        }
      }
    }),
  ];

  imoveisFiltered = [
    ...imoveisFiltered,
    ...imoveisFilt.filter((imov) => {
      if (props.isTerreno) {
        if (imov.attributes.Tipo == "Terreno") {
          return true;
        }
      }
    }),
  ];

  return imoveisFiltered;
};
const filterTipoCondominios = (condominiosFilt, props) => {
  let condominiosFiltered;

  condominiosFiltered = condominiosFilt.filter((imov) => {
    if (props.isApartamento) {
      if (imov.attributes.Tipo == "Apartamento") {
        return true;
      }
    }
  });

  condominiosFiltered = [
    ...condominiosFiltered,
    ...condominiosFilt.filter((imov) => {
      if (props.isCasa) {
        if (imov.attributes.Tipo == "Casa") {
          return true;
        }
      }
    }),
  ];

  condominiosFiltered = [
    ...condominiosFiltered,
    ...condominiosFilt.filter((imov) => {
      if (props.isTerreno) {
        if (imov.attributes.Tipo == "Terreno") {
          return true;
        }
      }
    }),
  ];

  return condominiosFiltered;
};
