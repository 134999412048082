import axios from "axios";
import { motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import {
  LastCard,
  LastReleasesDiv,
  LeftArrow,
  RightArrow,
  SecondTitle,
  SeeMoreCard,
} from "./styled";

const LastReleases = (props) => {
  const carousel = useRef();
  const [width, setWidth] = useState(0);
  const [qtdItems, setQtdItems] = useState(0);
  const [slide, setSlide] = useState(0);
  const [Lancamentos, setLancamentos] = useState([]);

  function strToURL(str) {
    return str
      .toLowerCase()
      .trim()
      .replace(/[áàãâä]/g, "a")
      .replace(/[éèẽêë]/g, "e")
      .replace(/[íìĩîï]/g, "i")
      .replace(/[óòõôö]/g, "o")
      .replace(/[úùũûü]/g, "u")
      .replace(/ç/g, "c")
      .replace(/(\ |_)+/, " ")
      .replace(/(^-+|-+$)/, "")
      .replace(/[^a-z0-9]+/g, "-");
  }

  useEffect(() => {
    axios
      .get(
        "https://painel.freedomplanejamento.com.br/api/condominios?populate=*"
      )
      .then((result) => {
        const lancamentos = result.data.data.filter((item, index) => {
          if (index <= 5) {
            return item;
          }
        });
        setLancamentos(lancamentos);
      });
  }, []);

  useEffect(() => {
    setQtdItems(Lancamentos.length);
    setWidth(carousel.current?.clientWidth - Lancamentos.length * 450);
  }, [Lancamentos]);

  return (
    <LastReleasesDiv style={{ backgroundColor: "#1F1F1F", padding: "100px 0" }}>
      <div className="__wrap">
        <SecondTitle>
          Últimos
          <br /> Lançamentos
        </SecondTitle>

        <motion.div
          ref={carousel}
          className="Carousel"
          whileTap={{ cursor: "grabbing" }}
        >
          <LeftArrow
            onClick={() => {
              if (slide + 405 <= 0) {
                setSlide(slide + 405);
              }
            }}
          />
          <RightArrow
            onClick={() => {
              if (slide - 405 >= width) {
                setSlide(slide - 405);
              }
            }}
          />
          <motion.div
            className="inner"
            style={{ width: qtdItems * 450 + "px" }}
            animate={{ x: slide + "px" }}
            drag={"x"}
            dragConstraints={{ right: 0, left: width }}
          >
            {Lancamentos.map((item) => (
              <motion.div key={item.id} className={"item"}>
                <LastCard
                  style={{
                    backgroundImage: `url(https://painel.freedomplanejamento.com.br${item.attributes?.Fotos.data[0].attributes.url})`,
                  }}
                >
                  <Link
                    to={`/empreendimentos/${strToURL(
                      (typeof item.attributes.Condominio_Fechado !== "undefined"
                        ? "imov"
                        : "condo") +
                        "-" +
                        item.attributes.Nome +
                        "-" +
                        item.id
                    )}`}
                  >
                    <p>{item.attributes.Nome}</p>
                    <SeeMoreCard className="seeMore">Veja Mais</SeeMoreCard>
                  </Link>
                </LastCard>
              </motion.div>
            ))}
          </motion.div>
        </motion.div>
      </div>
    </LastReleasesDiv>
  );
};

export default LastReleases;
