import styled from "styled-components";

const LastReleasesDiv = styled.div`
  @media only screen and (max-width: 430px) {
    padding: 50px 20px !important;
  }

  .Carousel {
    overflow: hidden;
    cursor: grab;

    .inner {
      display: flex;
  
      .item {
        min-width: 385px;
        margin-right 20px;
        height: 385px;
      }
    }
  }
`;

const SecondTitle = styled.h2`
  font-size: 36px;
  color: white;
  font-weight: 500;
  margin-bottom: 60px;
  position: relative;
`;

const RightArrow = styled.div`
  position: absolute;
  top: 78px;
  right: 0;
  width: 15px;
  height: 15px;
  border-top: 4px solid #575656;
  border-right: 4px solid #575656;
  transform: rotate(45deg) scale(1);
  cursor: pointer;
  transition: transform 0.5s ease;

  &:hover {
    transform: rotate(45deg) scale(1.15);
  }
`;

const LeftArrow = styled.div`
  position: absolute;
  top: 78px;
  right: 45px;
  width: 15px;
  height: 15px;
  height: 15px;
  border-top: 4px solid #575656;
  border-left: 4px solid #575656;
  transform: rotate(-45deg) scale(1);
  cursor: pointer;
  transition: transform 0.5s ease;

  &:hover {
    transform: rotate(-45deg) scale(1.15);
  }
`;

const LastCard = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  background-size: 180%;
  background-repeat: no-repeat;
  background-position: center center;
  font-weight: 500;
  color: white;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-end;
  border-radius: 16px;
  padding: 20px;
  transition: background-size 0.5s ease;

  &:hover {
    background-size: 220%;

    p {
      transform: translateY(0%);
    }
    .seeMore {
      transform: translateY(0%);
    }
  }

  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    border-radius: 16px;
    z-index: 0;
    height: 100%;
    background: linear-gradient(to top, #181818e8 0%, transparent 200%);
  }

  p {
    z-index: 1;
    position: relative;
    font-size: 20px;
    transform: translateY(130%);
    transition: transform 0.5s ease;
  }

  a {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    flex-direction: column;
    z-index: 9;
  }
`;

const SeeMoreCard = styled.strong`
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  font-weight: 400;
  margin-top: 10px;
  transform: translateY(200%);
  color: white;
  transition: transform 0.4s ease;
  z-index: 1;

  &:after {
    content: "";
    width: 6px;
    height: 6px;
    border-top: 2px solid;
    border-right: 2px solid;
    transform: rotate(45deg) translate(5px, -5px);
  }
`;

export {
  LastCard,
  LastReleasesDiv,
  LeftArrow,
  RightArrow,
  SecondTitle,
  SeeMoreCard,
};
